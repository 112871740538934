import { LineHeightOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Input, message, Space, Table, Tag } from 'antd'
import Text from 'antd/lib/typography/Text'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import useAuth from '../../../hooks/useAuth'
import useAxios from '../../../hooks/useAxios'
import useNotification from '../../../hooks/useNotification'
import { EXAM, PATIENT_INFO } from '../../../utils/endpoints'
import SearchInput from '../../SearchInput'
import ModalComponent from '../ModalComponent'
import PatientItem from '../PatientItem'
import styles from './index.module.css'
import { patientMockData } from './patientdata'


const PatientList = () => {

    const {patientID, patientInfo} = useSelector(state => state.auth)
    const { isProfessional } = useAuth()
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [email, setEmail] = useState()
    const [searchPatient, setSearchPatient] = useState("")
    const [emailError, setEmailError] = useState(false)

    const { data: patientData, error: patientError, isLoading: creatingPatient, dispatchFetch: createPatient, } = useAxios({ method: "POST", url: PATIENT_INFO});

    const {data: allPatientData, isLoading:loadingAllPatients, dispatchFetch: fetchAllPatientData,} = useAxios({ method: "GET", url: PATIENT_INFO + '/all', params: { asList: 1} });

    const {data: allExamData, isLoading:loadingAllExams, dispatchFetch: fetchAllExamData,} = useAxios({ method: "GET", url: EXAM + '/all', params: { asList: 1} });

    const {notificationData, notificationError, submittingNotificationData, dispatchNotification} = useNotification()

    const patientUsers = allPatientData?.data?.data.filter((item) => item.Specialist_1 === patientID)

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        setEmail('')
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setEmail('')
    };

    const handleChange = (e) => {
          // check for valid email
          if(e.target.name === "email"){
            const email = e.target.value
            const emailRegex = /\S+@\S+\.\S+/
            if(!emailRegex.test(email)){
                setEmailError(true)
            } else{
                setEmailError(false)
            }
        }
        const value = e.target.value
        setEmail(value.trim())
    }

    // Search Patient
    const handlePatientSearch = () => {
        const searchedPatientData = patientUsers.filter((patient) => {
            return patient?.First_Name?.toLowerCase().includes(searchPatient.toLowerCase()) ||
                patient?.Last_Name?.toLowerCase().includes(searchPatient.toLowerCase()) || 
                patient?.id?.toLowerCase().includes(searchPatient.toLowerCase()) || 
                patient?.Email?.toLowerCase().includes(searchPatient.toLowerCase())
        })
        return searchedPatientData
    }
   
    const patientListColumns = [
        {
            title: 'Full Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => (
                <Text>{(record.First_Name && record.Last_Name) ? `${record?.First_Name} ${record?.Last_Name}` : 'Patient'}</Text>
            )
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
            render: (_, record) => (
                <Text>{record?.Email || '--'}</Text>
            )
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone',
            key: 'phone',
            render:(_, record) => {
                return (
                    <Text>{ record?.Phone ? `(+1)${record?.Phone}` : "--"}</Text>
                )
            }
        },
        {
            title: 'Patient ID',
            dataIndex: 'id',
            key: 'id',
            render:(_, record) => {
                return (
                    <Text>{record?.Readable_ID || "--"}</Text>
                )
            }
        },
        // {
        //     title: 'Exam ID',
        //     dataIndex: 'exam_id',
        //     key: 'exam_id',
        //     render:(_, record) => {
        //         const examInfo = allExamData?.data?.data.find((exam) => exam.Assigned_Professional === patientID && exam.Patient_Info === record?.id)
        //         return (
        //             <Text>{examInfo?.Name || "--"}</Text>
        //         )
        //     }
        // },
        {
            title: 'Exam Status',
            dataIndex: 'exam_status',
            key: 'exam_status',
            render:(_, record) => {
                const examInfo = allExamData?.data?.data.find((exam) => exam.Assigned_Professional === patientID && exam.Patient_Info === record?.id)
                const getExamStatus = (status) => {
                    if(status === "true"){
                        return <Tag color="green">Completed</Tag>
                    }
                    else if(status === "false"){
                        return <Tag color="orange">In Progress</Tag>
                    }else{
                        return <Tag color="red">Pending</Tag>
                    }
                }
                return (
                    (loadingAllExams ? '--' : getExamStatus(examInfo?.Completed))
            )}
        },
    ];

    // Invite Patient
    const invitePatient = () => {
        isProfessional ? 
        (
            email ? (createPatient([
            {
                id: "",
                data: {
                    Organization: patientInfo?.Organization,
                    Email: email,
                    Specialist_1: patientID
                }
            }]) && setEmail('')) : 
            message.error("Enter patient email")
        ) :
        message.error("Only health professionals can invite patient")
    }

    // Send Patient Email after creating patient ID
    useEffect(() => {
        if(patientData?.data[0]?.status === "success"){
            dispatchNotification({
                username:email,
                website_url:`${process.env.REACT_APP_WEBSITE_URL}`,
                password:patientData?.data[0]?.data?.Readable_ID
            }, email, process.env.REACT_APP_SENDGRID_TEMPLATE_ID)
            message.success("Patient invited")
            fetchAllPatientData()
            setEmail('')
            setIsModalVisible(false)
        }
        patientError && message.error(patientError)
    }, [patientData, patientError])

    useEffect(() => {
        notificationData?.data[0]?.status === "success" && message.success("Email sent to patient")
    }, [notificationData])

    useEffect(() => {
        fetchAllPatientData();
        fetchAllExamData()
    },[])

    return (
        <>
            <div className={styles.patient_list_container}>
                <div className={styles.patient_list_header}>
                    <h2>Patients</h2>
                    <Space size={[8, 8]} wrap>
                        <SearchInput title="Patient" searchTerm={searchPatient} handleSearch={handlePatientSearch} setSearchTerm={setSearchPatient}/>
                        <Button type='primary' className={`button --primary ${styles.invite_btn}`} onClick={showModal}>
                            Invite Patient
                        </Button>
                    </Space>
                </div>
                <div className={styles.patient_list}>
                    {/* {
                        loadingAllPatients ? "Loading..." :
                        patientUsers?.map((item) => {
                            return <PatientItem key={item.id} {...item}/>
                        })
                    } */}
                    <Table dataSource={searchPatient ? handlePatientSearch() : patientUsers} columns={patientListColumns} loading={loadingAllPatients} scroll={{ x: 800 }}/>
                </div>
            </div>
            <ModalComponent title="Invite Patients" isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} width={400}>
                <div className={styles.invite_email_form}>
                    <Input 
                        className="input-secondary" 
                        name="email" 
                        onChange={handleChange} 
                        placeholder="Type Patient Email here"  
                        value={email}/>
                    {
                        emailError && <p className={styles.email_error}>Enter a valid email address!</p>
                    }
                    <Button 
                        type="primary" 
                        className='--card-button-active' 
                        style={{marginTop:"20px"}} 
                        onClick={invitePatient}
                        loading={creatingPatient}
                        disabled={!email || emailError}
                    >INVITE</Button>
                </div>
            </ModalComponent>
        </>
    )
}

export default PatientList