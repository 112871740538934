import { Typography } from 'antd'
import React from 'react'

const { Title, Paragraph } = Typography

const About = () => {
    
    return (
        <div style={{marginTop:40}}>
            <Title level={3} style={{textAlign:"center"}}>Welcome to Vitally Aging</Title>

            <Paragraph style={{fontSize:18}}>At Vitally Aging, we are deeply committed to revolutionizing geriatric healthcare. With a profound understanding of the unique needs of our senior community, we've partnered with the esteemed Saint Louis University to pioneer proactive solutions that empower individuals on their health journey. Our cutting-edge assessments, developed in collaboration with leading experts, are designed to identify treatable geriatric problems before they escalate, ensuring a higher quality of life and peace of mind for our cherished elders.</Paragraph>

            <Paragraph style={{fontSize:18}}>We offer more than just assessments; we provide a holistic approach to senior health. Our user-friendly assessments cover various aspects of geriatric well-being, enabling a comprehensive understanding of health status. By choosing Vitally Aging, you're choosing expertise backed by rigorous research, a compassionate team dedicated to your well-being, and the empowerment to make informed healthcare decisions. Your health is our priority, and we are here to guide you towards a healthier, happier tomorrow.
            <br /> <br />
            Join us in embracing proactive healthcare, compassionate support, and a future filled with vitality. At Vitally Aging, your well-being is not just our commitment; it's our passion. Discover the difference today – your journey to optimal health starts here.</Paragraph>
        </div>
    )
}

export default About