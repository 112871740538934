export const paths = {
    PATIENT_LOGIN: "/login",
    PATIENT_ENTRY: "/patient-entry",
    PATIENT_INFO: "/patient-info",
    DASHBOARD: "/dashboard",
    EXAM: "/dashboard/:exam",
    RESULTS: "/results",
    SUGGESTIONS: "/results/suggestions/:exam",
    SUMMARY: "/results/summary/:exam_id",
    ADMIN_LOGIN: "/admin/login",
    PROFESSIONAL: "/admin/professional",
    ORGANIZATION: "/admin/organization",
    ORDER_SHEET: "/admin/order-sheet/:patient_id/:exam_id",
    CHANGE_PASSWORD: "/admin/change-password",
    PROFILE: "/admin/profile",
    STATISTICS: "/admin/organization/statistics",
    MANAGE_PROFESSIONALS: "/admin/organization/manage-professionals",
    MANAGE_PATIENTS: "/admin/organization/manage-patients",
    ABOUT_US: "/about-us",
    CONTACT_US: "/contact-us"
}