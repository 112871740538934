import { Button, Space } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import NextButton from '../NextButton';
import styles from './index.module.css'

const MultiPickList = ({multiPickListArray, handleMultiPickListOption, setAnswerResponse, setPoints, nextQuestion, name, diets}) => {

    const [dietScore, setDietScore] = useState(0)
    const [submit, setSubmit] = useState(false)
    const dietStr = diets.join(";")

    //For the diet question, it's one point if they select 'Mechanically Altered', 2 points if they select 'Feeding Tube', and 0 points if neither is selected. The other options don't contribute to the points.
    const updateDietScore = () => {
        if(diets.includes("Mechanically Altered")){
            setDietScore((prevScore) => prevScore + 1)
        }
        if(diets.includes("Feeding Tube")){
            setDietScore((prevScore) => prevScore + 2)
        }
    }

    

    const handleSubmit = () => {
        updateDietScore()
        setSubmit(true)
        nextQuestion()
    }

    useEffect(() => {
        if(submit){
            setAnswerResponse((prevAnswerResponse) => ({
                ...prevAnswerResponse,
                [name]:dietStr
            }))
    
            setPoints((prevPoint) => ([
                ...prevPoint,
                dietScore
            ]))
        }
    }, [submit])

    return (
        <Space size={[40, 0]} className={styles.multipicklist}>
            <Space size={[0, 0]} className={styles.multipicklist_space} wrap>
                {
                    multiPickListArray.map((option, i) => {
                        return (
                            <Button type="default" className={`button --outline ${diets.includes(option.value) && '--outline-selected'} ${styles.multipicklist_btn}`}
                                style={{width:"inherit", minWidth:'130px'}}
                                key={option.id}
                                onClick={() => handleMultiPickListOption(option.value, i)}
                            > 
                                {option.name}
                            </Button>
                        )
                    })
                }
            </Space>
            <NextButton handleSubmit={handleSubmit} disabled={diets.length === 0 ? true : false}/>
        </Space>
    )
};

export default MultiPickList;
