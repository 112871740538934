export const ROLES = [
    {
        id:1,
        value:"Organization Admin"
    },
    {
        id:2,
        value:"Hospital Admin"
    },
    {
        id:3,
        value:"Hospital Staff"
    },
    {
        id:4,
        value:"Nurse"
    },
    {
        id:5,
        value:"Doctor"
    },
    {
        id:6,
        value:"Hospital Executive Team"
    },
]