import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useAuth from '../../../hooks/useAuth'
import styles from './index.module.css'
import { Space } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { paths } from '../../../routes/paths'
import { MedicineBoxOutlined, PieChartOutlined, UserAddOutlined } from '@ant-design/icons'
import { getFullName, nameFormat } from '../../../utils/helper'
import useAxios from '../../../hooks/useAxios'
import { CONTACT, PATIENT_INFO } from '../../../utils/endpoints'

const WelcomeBanner = () => {

  const auth = useSelector(state => state.auth);
  const {patientInfo} = useSelector(state => state.auth)
  const { isAdmin, isProfessional } = useAuth()
  const location = useLocation()


  const subNav = [
    {
      name: 'Statistics',
      path: paths.ORGANIZATION,
      icon: <PieChartOutlined />
    },
    {
      name: 'Professionals',
      path: paths.MANAGE_PROFESSIONALS,
      icon: <MedicineBoxOutlined />
    },
    {
      name: 'Patients',
      path: paths.MANAGE_PATIENTS,
      icon: <UserAddOutlined />
    }
  ]

  const {
		data: contactOrgsData,
		isLoading: fetchingContactOrgs,
		dispatchFetch: dispatchContactOrgsData,
	} = useAxios({ method: "POST", url: CONTACT, params: { id: auth?.patientID, asList: 1} });

  const { 
		data: patientOrgsData, 
		isLoading: fetchingPatientOrgs, 
		dispatchFetch: dispatchPatientOrgsData 
	} = useAxios({ method: "POST", url: PATIENT_INFO, params: { id: auth?.patientID, asList: 1 } });

  useEffect(() => {
    dispatchContactOrgsData(
      	{
			"related_objects":[
				{
				"reference_field":"Organization",
				"object_name":"Organization_Info",
				"object_fields":["Name"]
				}
			]
      	}   
    )
	dispatchPatientOrgsData(
		{
		  "related_objects":[
			  {
			  "reference_field":"Organization",
			  "object_name":"Organization_Info",
			  "object_fields":["Name"]
			  }
		  ]
		}   
  )
  }, [])

  if(fetchingContactOrgs || fetchingPatientOrgs){
	  return null
  }
  if((isAdmin || isProfessional) && !patientInfo?.Full_Name){
    	return null
  } 
  if(!isAdmin && !isProfessional && !patientInfo?.First_Name && !patientInfo?.Last_Name){
    	return null 
  }

  return (
    <div className={styles.welcome_banner}>
        <div className={styles.container}>
          { 
		  	(isAdmin || isProfessional) ?
			<p>{nameFormat(patientInfo?.Full_Name) || (isAdmin ? 'Admin' : 'Professional')} {patientInfo?.Title ? `(${patientInfo?.Title}, ${contactOrgsData?.data?.data?.[0]?.Organization?.Name} Org)` : ''}</p> :
			<p>{nameFormat(getFullName(patientInfo?.First_Name, patientInfo?.Last_Name))} {`(Patient, ${patientOrgsData?.data?.data?.[0]?.Organization?.Name} Org)`}</p>
		  }
          {
            isAdmin && (
              <Space size={30}>
                {
                  subNav.map((item, index) => (
                    <Link className={`${styles.subnav_link} ${location.pathname === item.path && styles.active_subnav_link}`} key={index} to={item.path}>
                      {item.icon} {" "}
                      {item.name}
                      </Link>
                  ))
                }
            </Space>
            )
          } 
        </div>
		 
    </div>
  )
}

export default WelcomeBanner 