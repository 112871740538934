import React from 'react'
import LoginContainer from '../../components/Admin/LoginContainer'

const Login = () => {
    return (
        <>
            <LoginContainer/>
        </>
    )
}

export default Login
