import React from 'react'
import styles from './index.module.css'

const SearchInput = ({title, searchTerm, setSearchTerm, handleSearch}) => {
  return (
    <div>
         <input
            className={styles.search_input}
            placeholder={`Search ${title}`}
            name="searchTerm"
            value={searchTerm}
            onChange={(e) => {
                handleSearch()
                setSearchTerm(e.target.value)
            }}
        />
    </div>
  )
}

export default SearchInput