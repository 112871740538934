import React, { useEffect } from 'react'
import ResultsHeader from '../components/ResultsHeader'
import SuggestionDetails from '../components/SuggestionDetails'

const Suggestions = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ResultsHeader
                adminHeaderText="Suggestions"
             /> 
            <SuggestionDetails />
        </>
    )
}

export default Suggestions
