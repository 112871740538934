import React from 'react';
import styles from './index.module.css';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const LoadingState = ({text}) => {
    const antIcon = <LoadingOutlined style={{ fontSize: 40, color: "#324852" }} spin />
    return (
        <div className={styles._}>
            <Spin size="large" indicator={antIcon}/>
            <h2 className={styles.loading_header}>{text}</h2>
        </div>
    )
}

export default LoadingState
