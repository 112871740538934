
export const EXAM_LIST = [
    {
        id:1,
        title:"lifead",
        description: "The LIFEAD test determines your quality of life.",
        imageUrl: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1624623455950x186868846135755940%2F1.png?w=192&h=209&auto=compress&fit=crop&dpr=1.5",
        link:'/lifead',
        preferredScoreLevel: 'high',
        maxScore:12,
        recommendation: "Your quality of life appears to be poor, discuss with your health provider what may be affecting your quality of life"
    },
    {
        id:2,
        title:"alone",
        description: "The ALONE test examines how social you are. ",
        imageUrl: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1624623597385x466264159084047940%2F2.png?w=192&h=209&auto=compress&fit=crop&dpr=1.5",
        link:'/alone',
        preferredScoreLevel: 'high',
        maxScore:10,
        recommendation: "Ask your physician to refer you to a social worker or a Circle of Friends program"
    },
    {
        id:3,
        title:"energy",
        description: "The ENERGY test determines your vitality.",
        imageUrl: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1624623732965x524035662789738900%2F3.png?w=192&h=209&auto=compress&fit=crop&dpr=1.5",
        link:'/energy',
        preferredScoreLevel: 'high',
        maxScore:12,
        recommendation: "You appear to have low energy. Measure B12, TSH, and CBC, Sleep Study"
    },
    {
        id:4,
        title:"amsad",
        description: "The AMSAD test determines if you're satisfied with your life.",
        imageUrl: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1624623879917x727220364528339500%2F4.png?w=192&h=209&auto=compress&fit=crop&dpr=1.5",
        link:'/amsad',
        preferredScoreLevel: 'low',
        maxScore:10,
        recommendation: "Ask physician to consider treatment for depression"
    },
    {
        id:5,
        title:"adls",
        description: "The ADLS & IADLS tests examine your ability to carry out the activities of daily living.",
        imageUrl: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1624624025784x540795337663261250%2F5.png?w=192&h=210&auto=compress&fit=crop&dpr=1.5",
        link:'/adls',
        preferredScoreLevel: 'high',
        maxScore:6,
        recommendation: "Ask your physician if you would benefit from occupational therapy or physical therapy"
    },
    {
        id:6,
        title:"iadls",
        description: "",
        imageUrl: "",
        link:'/iadls',
        preferredScoreLevel: 'high',
        optional: true,
        maxScore:6,
        recommendation: "Ask your physician if you would benefit from occupational therapy or physical therapy" 
    },
    {
        id:7,
        title:"frail-nh",
        description: "The FRAIL-NH test examines your ability to maintain function on an everyday basis.",
        imageUrl: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1598540621417x324694334543156100%2F4.png?w=128&h=147&auto=compress&fit=crop&dpr=1.5",
        link:'/frail-nh',
        preferredScoreLevel: 'lower',
        maxScore:12,
        recommendation: "Ask your physician if you would benefit from occupational therapy or physical therapy"
    },
    {
        id:8,
        title:"frail",
        description: "The FRAIL test will examine whether or not you have any geriatric syndrome",
        imageUrl: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1624624241869x633283130971802500%2F6.png?w=192&h=209&auto=compress&fit=crop&dpr=1.5",
        link:'/frail',
        preferredScoreLevel: 'high',
        maxScore:5,
        recommendation: ""
    },
    {
        id:9,
        title:"snaq",
        description: "The SNAQ test assesses whether or not you have a problem with your appetite.",
        imageUrl: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1624625773383x243552583554475400%2F7.png?w=192&h=210&auto=compress&fit=crop&dpr=1.5",
        link:'/snaq',
        preferredScoreLevel: 'high',
        maxScore:20,
        recommendation: "Recommend Caloric supplement"
    },
    {
        id:10,
        title:"weight-survey",
        description: "",
        imageUrl: "",
        link:'/weight-survey',
        preferredScoreLevel: 'none',
        optional: true,
        maxScore:0
    },
    {
        id:11,
        title:"eat-survey",
        description: "",
        imageUrl: "",
        link:'/eat-survey',
        preferredScoreLevel: 'high',
        optional: true,
        maxScore:10,
        recommendation: "If you have problem swallowing, talk to your physician about it" 
    },
    {
        id:12,
        title:"rcs",
        description: "The Rapid Cognitive Screening is a test of your memory.",
        imageUrl: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1624626176340x890213112029172700%2F8.png?w=192&h=209&auto=compress&fit=crop&dpr=2",
        link:'/rcs',
        preferredScoreLevel: 'high',
        maxScore:10,
        recommendation: "Consider treatment for cognitive problems",
        questions:[
            {
                id:1,
                label: "Please remember these five objects. We will ask you them later.",
                sub_label:["Apple ", "Pen ", "Tie ", "House ", "Car "],
                name:"",
                type:"",
            },
            {
                id:2,
                label: "Which of these clocks has the hours in the correct place?",
                sub_label: "",
                name:"Correct_Hours",
                type:"clock-hours-options",
            },
            {
                id:3,
                label: "Which of these clocks displays the time at 10 minutes to 11?",
                sub_label: "",
                name:"Correct_Time",
                type:"clock-times-options",
            },
            {
                id:4,
                label: "What are the 5 words previously mentioned?",
                sub_label: "",
                name:"Words_Correct",
                type:"multiple-input-options",
            },
            {
                id:5,
                label: "Read this story. You will be asked a question after.",
                sub_label:["Jill was a very successful stockbroker. She made a lot of money on the stock market. She then met Jack, a devastatingly handsome man. She married him and had three children. They live in Chicago. She then stopped work and stayed at home to bring up her children. When they were teenagers, she went back to work. She and jack lived happily ever after."],
                name:"",
                type:"",
            },
            {
                id:6,
                label:"What state did Jill live in?",
                sub_label: "",
                name:"Correct_State",
                type:'single-input-option',
            }
        ]
    },
    {
        id:13,
        title:"hearing-survey",
        description: "",
        imageUrl: "",
        link:'/hearing-survey',
        preferredScoreLevel: 'low',
        optional: true,
        maxScore:11, 
        recommendation: "Consider conducting a hearing test"
    },
    {
        id:14,
        title:"sarcf",
        description: "The SARC-F test examines how well your muscles are functioning.",
        imageUrl: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1624626569095x326033971553976200%2F9.png?w=192&h=209&auto=compress&fit=crop&dpr=2",
        link:'/sarcf',
        preferredScoreLevel: 'low',
        maxScore:12,
        recommendation: "You have sarcopenia and need to join an exercise program and take a leucine enriched amino acid supplement"
    },
    {
        id:15,
        title:"f3alls",
        description: "",
        imageUrl: "",
        link:'/f3alls',
        preferredScoreLevel: 'none',
        optional: true,
        maxScore:0
    },
    {
        id:16,
        title:"adam",
        description: "The ADAM test is a test for males that evaluates levels of testosterone. ",
        imageUrl: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1598540462288x453240473567351600%2F3.png?w=192&h=226&auto=compress&fit=crop&dpr=2",
        link:'/adam',
        preferredScoreLevel: 'high',
        maxScore:10,
        recommendation: "You need to get a bioavailable testosterone and luteinizing hormone. If low, discuss whether you need testosterone with your health provider."
    },
    {
        id:17,
        title:"what-matters",
        description: "The What Matters questionaire determines what is important to you.",
        imageUrl: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1598541215610x958033021557713800%2F7.png?w=192&h=219&auto=compress&fit=crop&dpr=2",
        link:'/what-matters'
    },
]