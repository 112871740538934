import { Button, Col, Form, Input, message, Modal, Row, Select, Space, Table, Tag } from 'antd'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useAuth from '../../../hooks/useAuth'
import useAxios from '../../../hooks/useAxios'
import useNotification from '../../../hooks/useNotification'
import { ADD_OAUTH, CONTACT, PATIENT_INFO, PROCLE_GUEST_USER } from '../../../utils/endpoints'
import { ROLES } from '../../../utils/roles'
import ModalComponent from '../ModalComponent'
import styles from './index.module.css'
import Text from 'antd/lib/typography/Text';
import { EditOutlined, ScheduleOutlined } from '@ant-design/icons'
import SearchInput from '../../SearchInput'
import LoadingState from '../../LoadingState'


const status_codes = {
    "true":"green",
    "false":"red"
}

const OrganizationPatients = () => {

    const {patientInfo} = useSelector(state => state.auth)
    const { isAdmin } = useAuth()

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [user, setUser] = useState({email:"", firstName:"", lastName:""})
    const [emailError, setEmailError] = useState(false)
    const [assignedProfessional, setAssignedProfessional] = useState("")
    const [searchPatient, setSearchPatient] = useState("")
    const [recordId, setRecordId] = useState(null)
    const [selectedRecord, setSelectedRecord] = useState(null)
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { Option } = Select;

    const { data: patientData, error: patientError, isLoading: creatingPatient, dispatchFetch: createPatient, } = useAxios({ method: "POST", url: PATIENT_INFO});

    const {data: allContactData, isLoading:loadingAllContacts, dispatchFetch: fetchAllContactData,} = useAxios({ method: "GET", url: CONTACT + '/all', params: { asList: 1} });

    const {data: allPatientData, isLoading:loadingAllPatients, dispatchFetch: fetchAllPatientData,} = useAxios({ method: "GET", url: PATIENT_INFO + '/all', params: { asList: 1} });

    const {submittingNotificationData, dispatchNotification} = useNotification()

    const { data: guestUserProcleData, error: guestUserProcleError, isLoading: viewingProcleSchedule, dispatchFetch: viewProcleSchedule, } = useAxios({ method: "POST", url: PROCLE_GUEST_USER});

    const { data: scheduleUserProcleData, error: scheduleUserProcleError, isLoading: schedulingProcleUser, dispatchFetch: scheduleProcleUser, } = useAxios({ method: "POST", url: PROCLE_GUEST_USER});
    
    const { data: statusData, error: statusError, isLoading: actionLoading, dispatchFetch: handlePatientStatus } = useAxios({ method: "POST", url: PATIENT_INFO});

    const healthProfessionals = allContactData?.data?.data.filter((item) => item.HIPAA_Contact_Role === "User")
    const patientUsers = allPatientData?.data?.data

    const getProfessional = (id) => {
        const professional = healthProfessionals?.find((item) => item.id === id)
        return professional
    }


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setUser({email:"", firstName:"", lastName:""})
    };


    const handleChange = (e) => {
        // check for valid email
        if(e.target.name === "email"){
            const email = e.target.value
            const emailRegex = /\S+@\S+\.\S+/
            if(!emailRegex.test(email)){
                setEmailError(true)
            } else{
                setEmailError(false)
            }
        }
        const name = e.target.name
        const value = e.target.value
        setUser({
            ...user,
            [name]:value.trim()
        })
    }

    const handleSelect = (value) => {
        setAssignedProfessional(value)
    }

    // Search Patient
    const handlePatientSearch = () => {
        const searchedPatientData = patientUsers.filter((patient) => {
            return patient?.First_Name?.toLowerCase().includes(searchPatient.toLowerCase()) ||
                patient?.Last_Name?.toLowerCase().includes(searchPatient.toLowerCase()) || 
                patient?.id?.toLowerCase().includes(searchPatient.toLowerCase()) ||
                patient?.Email?.toLowerCase().includes(searchPatient.toLowerCase()) 
        })
        return searchedPatientData
    }
   

    // View Schedule
    const viewSchedule = () => {
        isAdmin ? viewProcleSchedule({
            cc:"+1",
            phone:patientInfo?.Phone_Number,
            patient_phone:""
        }) :
        message.error("Only Admin can view schedule")
    }

    // Create and Invite Patient
    const invitePatient = () => {
        isAdmin ? 
        (user.email ? createPatient([
        {
            id: "",
            data: {
                Organization: patientInfo?.Organization,
                Email: user.email,
                Specialist_1: assignedProfessional,
                Active: 1,
            }
        }]) : message.error("Enter patient email")) :
        message.error("Only Admins can invite patient")
    }

    // Schedule patient
    const scheduleUser = (professionalPhone, patientPhone) => {
       isAdmin ? scheduleProcleUser({
           cc:"+1",
           phone:professionalPhone,
           patient_phone:patientPhone
       }) :
       message.error("Only Admin can schedule a patient")
   }


    // Send Patient Email after creating patient ID
    useEffect(() => {
        if(patientData?.data[0]?.status === "success"){
            dispatchNotification({
                username:user.email,
                website_url:`${process.env.REACT_APP_WEBSITE_URL}`,
                password:patientData?.data[0]?.data?.Readable_ID
            }, user.email, process.env.REACT_APP_SENDGRID_TEMPLATE_ID)
            message.success("Patient invited")
            fetchAllPatientData();
            fetchAllContactData()
            setUser({email:"", firstName:"", lastName:""})
            setIsModalVisible(false)
        }
        patientError && message.error(patientError)
    }, [patientData, patientError])

    // Deactivate Patient's account
    const deactivatePatientAccount = () => {
        handlePatientStatus([
            {
                id: selectedRecord.id,
                data: {
                    Active: 0,
                }
            }
        ])
    }
 
    // Activate Patient's account 
    const activatePatientAccount = () => {
        handlePatientStatus([
            {
                id: selectedRecord.id,
                data: {
                    Active: 1,
                }
            }
        ])
     } 

    const patientUsersColumns = [
        {
            title: 'Full Name',
            dataIndex: 'name',
            key: 'name',
            width:'28%',
            render: (_, record) => (
                <Text>{(record.First_Name && record.Last_Name) ? `${record?.First_Name} ${record?.Last_Name}` : 'Patient'}</Text>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            // width:'28%',
            render: (_, record) => (
                <Text>{record?.Email || '--'}</Text>
            )
        },
        {
            title: 'Patient ID',
            dataIndex: 'id',
            key: 'id',
            width:'20%',
            render: (_, record) => (
                <Text>{record?.Readable_ID || '--'}</Text>
            )
        },
        {
            title: 'Assigned Professional',
            dataIndex: 'Specialist_1',
            key: 'Specialist_1',
            width:'28%',
            render:(_, record) => (
                <Text>{getProfessional(record?.Specialist_1)?.Full_Name || '--'}</Text>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render:(_, record) => (
                <Tag color={status_codes[record?.Active]}>{record?.Active === "true" ? "Active" : "Inactive"}</Tag>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render:(_, record) => (
                <Space size={10}>
                    <Button 
                    className='--table-btn' 
                    loading={record?.id === recordId && schedulingProcleUser} 
                    onClick={() => {  
                        scheduleUser(getProfessional(record?.Specialist_1)?.Phone_Number, record?.Phone)
                        setRecordId(record?.id)
                    }}
                    disabled>
                        {schedulingProcleUser ? 'redirecting' : <>Schedule</>}
                    </Button>
                    <div style={{width:1.5, height:30, backgroundColor:"#D9D9D9"}}></div>
                    { 
                    record?.Active === "true" ?
                    <Button className='--table-btn-danger' onClick={() => {
                        setShowConfirmModal(true)
                        setSelectedRecord(record)
                        }}>
                        Deactivate
                    </Button>
                    :
                    <Button className='--table-btn' onClick={() => {
                        setShowConfirmModal(true)
                        setSelectedRecord(record)
                        }}>
                        Activate
                    </Button>
                }
                </Space>
            )
        },
    ];


    useEffect(() => {
        fetchAllPatientData();
        fetchAllContactData()
    },[])

    useEffect(() => {
        if(statusData?.data[0]?.status === "success"){          
            fetchAllPatientData();
            fetchAllContactData()
            setShowConfirmModal(false)
            if(selectedRecord?.Active === "true"){
                message.success(`${selectedRecord?.First_Name || 'this patient'}'s account deactivated successfully`)
            }else{
                message.success(`${selectedRecord?.First_Name || 'this patient'}'s account activated successfully`)
            }
        }
        if(statusError){
            message.error("Error updating patient's status")
        }
    }, [statusData, statusError])

    useEffect(() => {
        if(guestUserProcleData?.data.status === "success"){
            window.open(`${guestUserProcleData?.data?.data}`);
        }
    }, [guestUserProcleData])

    useEffect(() => {
        if(scheduleUserProcleData?.data.status === "success"){
            window.open(`${scheduleUserProcleData?.data?.data}`);
        }
    }, [scheduleUserProcleData])

    if(loadingAllContacts || loadingAllPatients){
        return <LoadingState text="Fetching Patients Data" />
    }


    return (
        <>
            <Row>
                <Col lg={24}>
                    <div className={styles.org_header}>
                        <h2>Patient List</h2>
                        <div className={styles.org_btn_container}>
                            <Space size={[8, 8]} wrap>
                              <SearchInput title="Patient" searchTerm={searchPatient} handleSearch={handlePatientSearch} setSearchTerm={setSearchPatient}/>  
                                <Button className={`--outline-entry ${styles.org_btn}`} onClick={viewSchedule} loading={viewingProcleSchedule} disabled>{viewingProcleSchedule ? 'redirecting' : 'View Schedule'}</Button>
                                <Button className={`--outline-entry ${styles.org_btn}`} onClick={showModal}>Invite Patient</Button>
                            </Space>
                        </div>
                    </div>
                    <Table dataSource={searchPatient ? handlePatientSearch() : patientUsers} columns={patientUsersColumns} loading={loadingAllPatients} scroll={{ x: 800 }}/>
                </Col>
            </Row>

            <ModalComponent title="Invite Patients" isModalVisible={isModalVisible} handleCancel={handleCancel}>
                <div className={styles.invite_email_form}>
                    <div style={{marginBottom:"20px"}}>
                        <Input 
                            className="input-secondary" 
                            name="email" 
                            type='email'
                            value={user.email}
                            onChange={handleChange} placeholder="Type Patient Email here" 
                        />
                        {
                            emailError && <p className={styles.email_error}>Enter a valid email address!</p>
                        }
                    </div>
                    <Select className='select-primary select-secondary' placeholder="Assign Health Professional" style={{ width: '100%'}} onChange={handleSelect}>
                        { 
                            loadingAllContacts ? "Loading Professionals..." :
                            healthProfessionals?.map((professional) => (
                                <Option value={professional.id} key={professional.id}>{professional.Full_Name}</Option>
                            ))
                        }
                    </Select>
                    <Button 
                        type="primary" 
                        className='--card-button-active' 
                        style={{marginTop:"20px"}} 
                        onClick={invitePatient}
                        loading={creatingPatient}
                        disabled={!user.email || !assignedProfessional || emailError}
                    >
                        INVITE
                    </Button>
                </div>
            </ModalComponent>
            <Modal 
                title={`${selectedRecord?.Active === "true" ? 'Deactivate' : 'Activate'} User`}
                visible={showConfirmModal} 
                okButtonProps={{style:{backgroundColor:"rgb(58, 77, 143)", color:"#fff", border:"none"}, loading: actionLoading}}
                onOk={() => {
                    if(selectedRecord?.Active === "true"){
                        deactivatePatientAccount()
                    } else{
                        activatePatientAccount()
                    } 
                }}
                onCancel={() => setShowConfirmModal(false)}
                placement="center"
                >
                <div className={styles.confirm_deactivate}>
                    <h3>Are you sure you want to {selectedRecord?.Active === "true" ? 'deactivate' : 'activate'} {selectedRecord?.First_Name || 'this patient'}'s account?</h3>
                    {
                        selectedRecord?.Active === "true" ?
                        <p>Deactivating this user will prevent them from accessing the platform.</p> :
                        <p>Activating this user will grant them access to the platform.</p>
                    }
                </div>
            </Modal>
        </>
    )
}

export default OrganizationPatients

const ScheduleUser = ({specialist_id, phone_number}) => {
    const { isAdmin } = useAuth()

    const { data: contactData, dispatchFetch: dispatchContactData } = useAxios({ method: "GET", url: CONTACT,params: { id: specialist_id, asList: 1} });

    const { data: scheduleUserProcleData, error: scheduleUserProcleError, isLoading: schedulingProcleUser, dispatchFetch: scheduleProcleUser, } = useAxios({ method: "POST", url: PROCLE_GUEST_USER});

     // Schedule patient
     const scheduleUser = () => {
        isAdmin ? scheduleProcleUser({
            cc:"+1",
            phone:contactData?.data?.data[0].Phone_Number,
            patient_phone:phone_number
        }) :
        message.error("Only Admin can schedule a patient")
    }


    useEffect(() => {
        dispatchContactData()
    }, [])

    if(scheduleUserProcleData?.data.status === "success"){
        window.open(`${scheduleUserProcleData?.data?.data}`);
    }

    return(
        <Button className='--table-btn' loading={schedulingProcleUser} onClick={scheduleUser}>{schedulingProcleUser ? 'redirecting' : <><ScheduleOutlined /> Schedule User</>}</Button>
    )
}

