import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_AUTH, LOAD_USER, LOGOUT } from '../redux/actions/types';
import { CONTACT, PATIENT_INFO, RESOURCE } from '../utils/endpoints';
import useAxios from './useAxios';

const useAuth = () => {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    

    // check if patient exist in organization
    const isValidPatient = auth?.patientInfo && auth?.patientInfo.length !== 0 ? true : false;

    // check if patient is registered
    const isRegistered = (auth?.patientInfo?.First_Name && auth?.patientInfo?.Last_Name && auth?.patientInfo?.Date_of_Birth && auth?.patientInfo?.Phone && auth?.patientInfo?.Sex) && true

     // check if admin is profile is updated
    const isAdminUpdated = (
        auth?.patientInfo?.First_Name !== null && 
        auth?.patientInfo?.Name !== null && 
        auth?.patientInfo?.Email_Address !== null && 
        auth?.patientInfo?.Phone_Number !== null && 
        auth?.patientInfo?.Birth_Date !== null &&
        auth?.patientInfo?.Sex !== null
    ) && true

    // authenticate patient when organization is authenticated and patient is valid
    const isAuth = (auth?.token && isValidPatient) ? true : false;

    // authenticate admin when organization is authenticated
    const isAdminAuth = auth?.token && true

    const isAdmin = auth?.patientInfo?.HIPAA_Contact_Role === "Admin" && true

    const isProfessional = auth?.patientInfo?.HIPAA_Contact_Role === "User" && true

    const isPasswordUpdated = auth?.patientInfo?.firstTimeLogin === "0" ? true : false 

    const saveAuth = ({readableId, patientID, token})=> {
        readableId && localStorage.setItem('awv_readableId', readableId)
        patientID && localStorage.setItem('awv_patientID', patientID)
        token && localStorage.setItem('awv_token', token)
       
        dispatch({
            type: LOAD_AUTH,
            payload: {
                readableId,
                patientID,
                token,
            }
        })
    }

    // Logout user
    const logout = ()=>{
        dispatch({
            type: LOGOUT
        })
    }

    // Fetch Patient Data by readable id* and store data in redux store
    const { data: singlePatientData, error: singlePatientError, isLoading: loadingPatientData, dispatchFetch: dispatchSinglePatientData } = useAxios({ method: "GET", url: PATIENT_INFO, params: { Readable_ID: auth?.readableId, asList: 1 } });



    useEffect(() => {
        dispatch({
            type: LOAD_USER,
            payload: singlePatientData?.data?.data[0]
        })

        // replace local storage with patient id instead of readable id
        saveAuth({patientID: singlePatientData?.data?.data[0]?.id, token: auth?.token})   
    }, [singlePatientData?.data?.data])

   
    // Fetch contact data (admin and professional) by id
    const {
        data: contactData,
        dispatchFetch: dispatchContactData,
    } = useAxios({ method: "GET", url: CONTACT, params: { id: auth?.patientID, asList: 1} });

    useEffect(() => {
        dispatch({
            type: LOAD_USER,
            payload: contactData?.data?.data[0]
        })
    }, [contactData?.data?.data])


    // Check for token expiration
    const {data: resourceData, error:resourceError, dispatchFetch: fetchResourceData,} = useAxios({ method: "GET", url: RESOURCE, params: { standardize: 1} });

    // Clear local storage if patient ID doesn't exist
    useEffect(() => {
        if(singlePatientData?.data?.data?.length === 0){
            logout()
            message.error("Your Patient ID is invalid")
        }
    }, [singlePatientData?.data?.data])



    return ({isAuth, isAdminAuth, isAdminUpdated, isValidPatient, isAdmin, isProfessional, singlePatientData, singlePatientError, isRegistered, loadingPatientData, isPasswordUpdated, resourceData, dispatchSinglePatientData, dispatchContactData, saveAuth, fetchResourceData, logout});
};

export default useAuth;