import { message } from 'antd';
import { useEffect } from 'react';
import { NOTIFICATION } from '../utils/endpoints';
import useAxios from './useAxios';
import { useSelector } from 'react-redux';

const useNotification = (sender_id) => {

    const { patientInfo } = useSelector(state => state.auth);

    const { data: notificationData, error: notificationError, isLoading: submittingNotificationData, dispatchFetch: submitNotificationData, } = useAxios({ method: "POST", url: NOTIFICATION});

    const dispatchNotification = (dynamic_data = {}, receiver_email, template_id) => {
        submitNotificationData(
            [
                {
                  id: "",
                  data: {
                    SenderOrg: patientInfo?.Organization,
                    // ReceivingContacts: [receiver_id], // array of contact ids
                    ReceivingCustom: {
                        "Email" : [receiver_email] // receiving custom accepts array of email addresses
                    },
                    Notifications: [
                      {
                            "Type": "Email",
                            "From": process.env.REACT_APP_EMAIL_FROM_ADDRESS,
                            "Template_id": template_id,
                            "Dynamic_Data": {
                               ...dynamic_data
                            }
                        }
                    ],
                    "Organization_Info": patientInfo?.Organization,
                    "Debug": 0
                  }
                }
            ]
        )
    }

    useEffect(() => {
        if(notificationData){
            console.log("Email sent successfully")
        }
    }, [notificationData])

    useEffect(() => {
        if(notificationError){
            message.error("An Error Occured. Email not sent.")
        }
    }, [notificationError])

    return {notificationData, notificationError, submittingNotificationData, dispatchNotification}
}

export default useNotification