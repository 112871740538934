
import { Button } from 'antd'
import React from 'react'
import NextButton from '../NextButton'
import OptionImage from '../OptionImage'
import WeightCalculator from '../WeightCalculator'
import styles from './index.module.css'

const QuestionExtras = ({show, type, examType, index, showNext, handleNextClick}) => {

    return (
        <div className={styles._}>
            {
                show && 
                <div className={styles.extra_container}>
                    {
                        type === 'weight' && 
                        <WeightCalculator />
                    }
                    {
                        type === 'image' && (examType === 'adls' && index === 0) && 
                        <OptionImage src="http://s3.amazonaws.com/appforest_uf/f1600355264033x219733661266952030/pain.gif" alt="pain value"/>
                    }
                    {
                        type === 'image' && (examType === 'adls' && index === 1) && 
                        <OptionImage src="http://s3.amazonaws.com/appforest_uf/f1605110789321x332900127004790000/standrink.jpg" alt="alcohol value drink"/>
                    }
                </div>
                
            }
            {
                showNext && <NextButton handleSubmit={handleNextClick}/>
            }
        </div>
    )
}

export default QuestionExtras
