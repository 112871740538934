import { Button, Modal, Result, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useAxios from '../../hooks/useAxios'
import { EXAM_LIST } from '../../utils/examdata'
import LoadingState from '../LoadingState'
import QuestionWrapper from '../QuestionWrapper'
import styles from './index.module.css'
import { paths } from '../../routes/paths'
import { PRIMARY_COLOR } from '../../utils/helper'
import useNotification from '../../hooks/useNotification'
import { CONTACT } from '../../utils/endpoints'
import { LOAD_EXAM } from '../../redux/actions/types'

const ExamEnd = ({isLoading, examType, endExam, setEndExam, setStartExam, examProgress, setExamProgress, refreshQuestions, snaqScore, answerError, answerData, assignedProfessional, submitAnswers, answerResponse}) => {

    let navigate = useNavigate()
    const dispatch = useDispatch()
    const {dispatchNotification} = useNotification()
    const { patientInfo } = useSelector(state => state.auth);
    const {exam_info} = useSelector(state => state.exam)
    const [openConfirmModal, setOpenConfirmModal] = useState(false)

    const { data: frailData, dispatchFetch: fetchFrail } = useAxios({ method: "GET", url: `/index.php/frail?Exam=${exam_info?.id}&asList=1`});
    const { data: weightData, dispatchFetch: fetchWeight } = useAxios({ method: "GET", url: `/index.php/weight-survey?Exam=${exam_info?.id}&asList=1`});
    const { data: sarcfData, dispatchFetch: fetchSarcf } = useAxios({ method: "GET", url: `/index.php/sarcf?Exam=${exam_info?.id}&asList=1`});
    const { data: rcsData, dispatchFetch: fetchRcs } = useAxios({ method: "GET", url: `/index.php/rcs?Exam=${exam_info?.id}&asList=1`});
    const { data: postExamData, error: postExamError, isLoading: submitting, dispatchFetch: submitExam } = useAxios({ method: "POST", url: `/index.php/exam`});

    const frailTest = frailData?.data.data[frailData?.data?.data.length - 1]
    const weightSurvey = weightData?.data.data[weightData?.data?.data.length - 1]
    const sarcfTest = sarcfData?.data.data[sarcfData?.data?.data.length - 1]
    const rcsTest = rcsData?.data.data[rcsData?.data?.data.length - 1]

    // Get current exam being answered by patient
    const currentExam = EXAM_LIST.find( ({title}) => { 
        return title === examType
    })

    // Get current exam index
    const currentExamIndex = EXAM_LIST.findIndex( ({title}) => { 
        return title === examType
    })

    // Get next exam in array
    const nextExam = EXAM_LIST[currentExamIndex + 1]?.title

     // Get last exam in exam array list
     const lastExam = EXAM_LIST[EXAM_LIST.length - 1]

    const examRouting = () => {
        setEndExam(false)
        setStartExam(false)

        if(currentExam){ 
            navigate(`${paths.DASHBOARD}/${nextExam}`) 
            window.location.reload()
        }
        if(examType === 'snaq'){
            if(frailTest?.Loss_of_weight === "1" || snaqScore < 14){
                navigate(`${paths.DASHBOARD}/weight-survey`)
                window.location.reload()
            }else{
                navigate(`${paths.DASHBOARD}/rcs`)
                setExamProgress('rcs')
                window.location.reload()
            }
        }
        if(examType === "weight-survey"){
            if(weightSurvey?.Trouble_Swallowing === "1"){
                navigate(`${paths.DASHBOARD}/eat-survey`)
                window.location.reload()
            }else{
                navigate(`${paths.DASHBOARD}/rcs`)
                window.location.reload()
            }
        }
        if(examType === "rcs"){
            if(rcsTest?.Score <= 7){
                navigate(`${paths.DASHBOARD}/hearing-survey`)
                window.location.reload()
            }else{
                navigate(`${paths.DASHBOARD}/sarcf`)
                window.location.reload()
            }
        }
        if(examType === "sarcf"){
            if(sarcfTest?.Times_Fallen === "None" && patientInfo?.Sex === "Male"){
                navigate(`${paths.DASHBOARD}/adam`)
                window.location.reload()
            } else if(sarcfTest?.Times_Fallen === "None" && patientInfo?.Sex === "Female"){
                navigate(`${paths.DASHBOARD}/what-matters`)
                window.location.reload()
            }else{
                navigate(`${paths.DASHBOARD}/f3alls`)
                window.location.reload()
            }
        }
        if(examType === "f3alls"){
            if(patientInfo?.Sex === "Male"){
                navigate(`${paths.DASHBOARD}/adam`)
                window.location.reload()
            } else {
                navigate(`${paths.DASHBOARD}/what-matters`)
                window.location.reload()
            }
        }
        if(examType === lastExam?.title){
            navigate(paths.RESULTS)  
        }
    }

    useEffect(() => {
        if(answerData?.data[0]?.message){
            message.success(`New ${examType?.toUpperCase()} test added successfully`)
        }
        if(answerError){
            console.log(answerError?.response)
            message.error('Test not submitted. Please try again.')
        }
    }, [answerData, answerError])

    useEffect(() => {
        answerData && fetchFrail()
        answerData && fetchWeight()
        answerData && fetchSarcf()
        answerData && fetchRcs()
    }, [answerData])


    useEffect(() => {
        if(examType === lastExam?.title){
            dispatchNotification({
                username:assignedProfessional?.Full_Name,
                target_link:`${process.env.REACT_APP_WEBSITE_URL}/results?user=admin&exam=${exam_info?.id}`,
            }, 
            assignedProfessional?.Email_Address, 
            process.env.REACT_APP_RESULT_SENDGRID_TEMPLATE_ID)

            // Update exam to completed after user answers last question
            if(examType === lastExam?.title){
                submitExam([
                    {
                        "id": exam_info?.id,
                        data:{ 
                            Progress: examProgress,
                            Completed:"true"
                        }
                    }
                ])
            }

            // Submit answers for the last test
            let data = {
                ...answerResponse
            }
            const body = [
                {
                    "id": "",
                    data
                }
            ]
            submitAnswers(body)
        }
    },[])

    useEffect(() =>{
        if(postExamData){
            dispatch({
                type: LOAD_EXAM,
                payload: {
                    ...exam_info,
                    Completed: "true",
                },
            })
        }
    }, [postExamData])


    if(isLoading){
        return(
            <LoadingState text="Submitting your response. Please wait..." />
        )
    }

    if(answerError){
        return(
            <div className="result_container">
                <Result
                    status="warning"
                    title="Something went wrong. Please check your internet connection and try again."
                    subTitle="If the problem persists, please contact your health professional."
                    extra={
                    <Button 
                        className="button --primary" key="restart"
                        onClick={() =>  window.location.reload()}
                    >
                    Start Again
                    </Button>
                    }
                />
            </div>
        )
    }


    if(examType === lastExam?.title){
        if(submitting){
            return(
                <LoadingState text="Submitting your response. Please wait..." />
            )
        }
        return(
            <QuestionWrapper 
                question="You've Completed your exam!"
            >
                <div style={{ paddingTop: '10px' }}>
                    <p style={{fontSize:'20px'}}>Thank you for taking the exam. We've informed your health professional and they will get in touch with you </p>
                    <div style={{textAlign:'center', marginTop:'30px'}}>
                        <Button className="button --primary" onClick={() =>  navigate('/results')}>
                            Continue
                        </Button>
                    </div>
                </div> 
            </QuestionWrapper>
        )
    }

    return (
        <div className={styles._}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <p>You have completed the {examType?.toUpperCase()} test</p>
                    <p>You may take a break and complete the exams later.</p>
                </div>
                <div className={styles.btn_container}>
                    <Button className="button --outline" onClick={() => examRouting()}>
                        Continue
                    </Button>
                    <Button className="button --outline" onClick={() =>  setOpenConfirmModal(true)}>Take a Break</Button>
                </div>
            </div>
            <Modal
                title="Take a break?"
                visible={openConfirmModal}
                okText="Yes"
                okButtonProps={{style:{backgroundColor:PRIMARY_COLOR}}}
                onOk={() => navigate(paths.DASHBOARD)}
                onCancel={() => setOpenConfirmModal(false)}
            >
                You need to complete the exam within 24 hours. If you exit the exam, you'll have to resume within the time limit, or your progress will be lost, and you'll have to restart. Are you sure you want to proceed?
            </Modal>
        </div>
    )
}

export default ExamEnd
