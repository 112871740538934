import React from 'react'
import styles from './index.module.css'

const Footer = () => {
    const year = new Date().getFullYear()
    return (
        <footer>
            <div className={styles.container}>
                <p>&copy; copyright 2021 - {`${year}`} by Vitally Aging, Inc.</p>
            </div>
        </footer>
    )
}

export default Footer
