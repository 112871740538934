import React, { useEffect } from 'react'
import AppRoutes from './routes'
import './App.css'
import useAuth from './hooks/useAuth'
import { message } from 'antd'

const App = () => {
  const { fetchResourceData, resourceData, logout, isAuth } = useAuth()

  //check if page is home page

  useEffect(()=> {
    fetchResourceData()
  }, [])

   // Log user out when token expires
  useEffect(() => {
      if(isAuth && resourceData?.data?.success === false){
          logout()
          message.error("Your session has expired")
      }
  }, [resourceData])


  return (
    <>
      <AppRoutes/>
    </>
  )
}

export default App

