import { message } from 'antd';
import * as types from '../actions/types';

const initialState = {
  readableId: localStorage.getItem('awv_readableId'),
  patientID: localStorage.getItem('awv_patientID'),
  token: localStorage.getItem('awv_token'),
  patientInfo: localStorage.getItem('awv_patientInfo') !== "undefined" && JSON.parse(localStorage.getItem('awv_patientInfo')),
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case types.LOAD_AUTH:
      return {
        ...state,
        readableId: action.payload.readableId || state.readableId,
        patientID: action.payload.patientID || state.patientID,
        token: action.payload.token || state.token,
      }
    case types.LOAD_USER:
      action.payload && localStorage.setItem('awv_patientInfo', JSON.stringify(action.payload));
      return{
        ...state,
        patientInfo:  action.payload || state.patientInfo,
      }
    case types.LOGOUT:
      localStorage.removeItem('awv_token');
      localStorage.removeItem('awv_patientID');
      localStorage.removeItem('awv_patientInfo');
      localStorage.removeItem('awv_readableId');
      return {
        ...state,
        patientID: null,
        token: null,
        patientInfo: null,
        readableId: null,
      }
    default:
      return state;
  }
}

export default reducer;