import React from 'react'
import styles from './index.module.css'
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/frail-logo.png'
import useAuth from '../../hooks/useAuth';
import { Avatar, message, Popover, Typography } from 'antd';
import { useSelector } from 'react-redux';
import {DownOutlined} from '@ant-design/icons';
import { useState } from 'react';
import { paths } from '../../routes/paths';

const { Paragraph } = Typography;

const Nav = () => {

    const { isAuth, isAdminAuth, isAdmin, isProfessional, isRegistered, logout } = useAuth()
    const {patientInfo} = useSelector(state => state.auth)
    const[menuOpen, setMenuOpen] = useState(false);

    const showMenu = () => {
        setMenuOpen(!menuOpen)
    }
    const handleLinkClick = (key) => {
        if(key === "dashboard" && !isRegistered && (!isAdminAuth || !isProfessional)){
            message.info("Please complete your details to access dashboard")
        }
        setMenuOpen(false)
    }

    const content = (
        <div className={styles.content}>
            <NavLink to={ (isAdmin || isProfessional) ? "admin/profile" : paths.PATIENT_INFO} className={styles.content_item}>
                Profile
            </NavLink>
           <div className={styles.content_item} onClick={() => handleLogout()}>
                Log out
            </div>
        </div>
    );

    
    const MENU_ITEMS = [
        {
            key: "dashboard",
            title:(isAuth || isAdminAuth) && "Dashboard",
            link: isAdmin ? "admin/organization" : isProfessional ? "admin/professional" : paths.DASHBOARD
        },
        {
            key: "about",
            title: "About Us",
            link: paths.ABOUT_US
        },
        {
            key: "login",
            title: "Log In",
            link: paths.PATIENT_ENTRY
        }
    ]
    

    const handleLogout = () => {
        logout()
        message.success("Logged out successfully")
    }

    return (
        <nav>
            <div className={styles.container}>
                <div className={styles.logo_section}>
                    <div className={styles.logo}>
                        <img src={logo} alt="logo" />
                    </div>
                    <div className={styles.logo_text}>
                        <h2>Aging Dr - AWV  <sup>Beta</sup></h2>
                    </div>
                </div>
                <div className={`${menuOpen ? styles.hamburger_menu+ ' ' + styles.toggle : styles.hamburger_menu}`} onClick={showMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className={`${menuOpen ? styles.menu_list+ ' ' + styles.menu_toggle : styles.menu_list}`}>
                    {
                       MENU_ITEMS.filter((item) => (isAuth || isAdminAuth) ? item?.key !== "login" : item).map((item) =>
                            <NavLink id={item?.key} to={item?.link} className={styles.menu_item} onClick={() => handleLinkClick(item?.key)}>
                                {item?.title}
                            </NavLink>
                        )
                    }
                   { 
                   (isAuth || isAdminAuth) &&
                        <>
                             {/* <div className={styles.menu_item} onClick={() => handleLogout()}>
                                Log out
                            </div> */}
                            <div className={styles.menu_item} >
                                 <Popover placement="bottomRight"  content={content} trigger="click">
                                    <div className={styles.menu_avatar}>
                                        <Avatar style={{ backgroundColor: '#0bc480', marginRight:"7px" }} size="medium">
                                                {patientInfo?.First_Name ? patientInfo?.First_Name[0]: 'U'}
                                        </Avatar>  
                                        <Paragraph className={styles.profile_name}>{patientInfo?.First_Name ? patientInfo?.First_Name : "User"}</Paragraph>
                                        <DownOutlined />
                                    </div>
                                </Popover> 
                            </div>
                        </>
                    }
                </div>
            </div>
        </nav>
    )
}

export default Nav
