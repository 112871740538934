import { Button, Form, Input, message } from 'antd'
import React, {useEffect} from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import useAxios from '../../../hooks/useAxios'
import { CHANGE_PASSWORD } from '../../../utils/endpoints'
import EntryContainer from '../../EntryContainer'

const ChangePasswordForm = () => {

    let navigate = useNavigate()
    const {patientID} = useSelector(state => state.auth)
    const { isAdminAuth, isAdmin, isAdminUpdated, isProfessional, isPasswordUpdated, dispatchContactData } = useAuth()

    const { data: changePasswordData, error: changePasswordError, isLoading: changing, dispatchFetch: changePassword,} = useAxios({ method: "POST", url: CHANGE_PASSWORD});


    const onFinish = (values) => {
        const body = {
            id: patientID,
            data: {
                Password: values.password
            }
        }
        changePassword(body)
    };

    useEffect(() => {
        changePasswordData?.data?.status === "success" && message.success("Password changed successfully")
        if(changePasswordData?.data?.status === "success"){
            navigate('/admin/profile')
        }
    }, [changePasswordData])

    useEffect(() => {
        changePasswordError && message.error(changePasswordError)
    }, [changePasswordError])

    useEffect(() => {
        dispatchContactData()
    },[])

    // if(!isAdminUpdated && (isProfessional || isAdmin) && isPasswordUpdated){
    //     return(
    //         <Navigate to="/admin/profile"/>
    //     )
    // }
    // if(isAdminUpdated && isProfessional && isPasswordUpdated){
    //     return(
    //         <Navigate to="/admin/professional"/>
    //     )
    // } 

    // if(isAdminUpdated && isAdmin && isPasswordUpdated){
    //     return(
    //         <Navigate to="/admin/organization"/>
    //     )
    // }   

    return (
        <EntryContainer title="Change Password" onFinish={onFinish}>
            <Form.Item
                name="password"
                rules={[
                {
                    required: true,
                    message: 'Please input your password!',
                },
                ]}
            >
                <Input.Password className="input" placeholder="Enter New Password"/>
            </Form.Item>
            <Form.Item
                name="confirm"
                dependencies={['password']}
                rules={[
                {
                    required: true,
                    message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }

                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                }),
                ]}
            >
                <Input.Password className="input" placeholder="Confirm New Password"/>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 24 }}>
                <Button loading={changing} style={{marginTop:'12px'}} htmlType="submit" className="button --secondary" >
                    CHANGE PASSWORD
                </Button>
            </Form.Item>
        </EntryContainer>
    )
}

export default ChangePasswordForm