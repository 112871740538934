import { Button } from 'antd';
import React from 'react';
import styles from './index.module.css'

const NextButton = ({handleSubmit, disabled}) => {

  return( 
    <div className={styles.next_btn}>
        <Button type="primary" className="button --primary"
            style={{width:"inherit", minWidth:'200px'}}
            disabled={disabled}
            onClick={() => handleSubmit()}
        >
            Next
        </Button>
    </div>
  )
};

export default NextButton;
