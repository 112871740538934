import { Typography } from 'antd'
import React from 'react'

const { Title, Paragraph } = Typography

const Contact = () => {
  return (
    <div style={{marginTop:40}}>
        <Title level={3} style={{textAlign:"center"}}>Contact Us</Title>
    </div>
  )
}

export default Contact