import { Card, Col, Row } from 'antd';
import React, { useEffect } from 'react'
import useAxios from '../../../hooks/useAxios';
import { CONTACT, EXAM, PATIENT_INFO } from '../../../utils/endpoints';
import styles from './index.module.css'
import exam_administered from '../../../assets/icons/exam_administered.png'
import exam_completed from '../../../assets/icons/exam_completed.png'
import administrator_icon from '../../../assets/icons/administrator_icon.png'
import patient_icon from '../../../assets/icons/patient_icon.png'

const StatisticsCard = ({allContactData, allPatientData, allExamData}) => {

    const completedExams = allExamData?.data?.data.filter((exam) => exam.Completed === "true")

    return (
        <Row className={styles.stat_details} gutter={[24,16]}>
            <Col xs={24} sm={24} lg={6}>
                <Card className={styles.stat_card}>
                    <div className={styles.card_info}>
                        <div>
                            <p>Administrators</p>
                            <h2>{allContactData?.data?.data.length || '--'}</h2>
                        </div>
                        <div className={styles.card_icon}>
                            <img src={administrator_icon} alt="administrator icon" />
                        </div>
                    </div>
                </Card>
            </Col>
            <Col xs={24} sm={24} lg={6}>
                <Card className={styles.stat_card}>
                    <div className={styles.card_info}>
                        <div>
                            <p>Patients</p>
                            <h2>{allPatientData?.data?.data.length || '--'}</h2>
                        </div>
                        <div className={styles.card_icon}>
                            <img src={patient_icon} alt="administrator icon" />
                        </div>
                    </div>
                </Card>
            </Col>
            <Col xs={24} sm={24} lg={6}>
                <Card className={styles.stat_card}>
                    <div className={styles.card_info}>
                        <div>
                            <p>Exams Administered</p>
                            <h2>{allExamData?.data?.data.length || '--'}</h2>
                        </div>
                        <div className={styles.card_icon}>
                            <img src={exam_administered} alt="exam administerd icon" />
                        </div>
                    </div>
                </Card>
            </Col>
            <Col xs={24} sm={24} lg={6}>
                <Card className={styles.stat_card}>
                    <div className={styles.card_info}>
                        <div>
                            <p>Exams Completed</p>
                            <h2>{completedExams?.length || '--'}</h2>
                        </div>
                        <div className={styles.card_icon}>
                            <img src={exam_completed} alt="exam completed icon" />
                        </div>
                    </div>
                </Card>
            </Col>
        </Row>
    )
}

export default StatisticsCard