import { Button, Col, Row } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import useAxios from '../../hooks/useAxios'
import styles from './index.module.css'

const ResultInfo = ({isAdmin, examId, patientInfo}) => {

    const {exam_info} = useSelector(state => state.exam)

    const { data: testData, isLoading: loadingTest, dispatchFetch: fetchTest } = useAxios({ method: "GET", url: `/index.php/what-matters?Exam=${!isAdmin ? exam_info?.id : examId}&asList=1`});

    const testDetails = testData?.data.data[testData?.data?.data.length - 1]

    useEffect(() => {
        fetchTest()
    }, [])

    return (
        <div className={styles._}>
             {
                !loadingTest &&
                <div className={styles.what_matters}>
                    <h3>What Matters</h3>
                    <Row gutter={24} className={styles.row}>
                        <Col>
                            <h4>Advanced Directive:</h4>
                        </Col>
                        <Col>
                            <p>{testDetails?.Completed_Advance_Directive === "0" ? "No" : "Yes"}</p>
                        </Col>
                    </Row>
                </div>
            }
            <div className={styles.patient_info}>
                <h3>Patient Information</h3>
                <div className={styles.container}>
                    <Link 
                        to={`/results/summary/${isAdmin? `${examId}?user=admin` : exam_info?.id}`}
                        state={patientInfo}
                    >See Patient Info</Link>
                </div>
            </div>
        </div>
    )
}

export default ResultInfo
