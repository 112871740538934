import { Button, Col, Form, Input, message, Modal, notification, Row, Select, Space, Table, Tag } from 'antd'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useAuth from '../../../hooks/useAuth'
import useAxios from '../../../hooks/useAxios'
import useNotification from '../../../hooks/useNotification'
import { ADD_OAUTH, CONTACT, EXAM, LOGIN_HISTORY, PATIENT_INFO, PROCLE_GUEST_USER } from '../../../utils/endpoints'
import { ROLES } from '../../../utils/roles'
import ModalComponent from '../ModalComponent'
import styles from './index.module.css'
import PatientUser from './patient_user'
import moment from 'moment';
import ProfessionalItem from './professional_item'
import Text from 'antd/lib/typography/Text';
import { EditOutlined, ScheduleOutlined } from '@ant-design/icons'
import ResetPassword from '../ResetPassword'
import SearchInput from '../../SearchInput'
import LoadingState from '../../LoadingState'

const status_codes = {
    "true":"green",
    "false":"red"
}

const OrganizationProfessionals = () => {

    const {patientInfo} = useSelector(state => state.auth)
    const { isAdmin } = useAuth()
    const {submittingNotificationData, notificationData, notificationError, dispatchNotification} = useNotification()

    const [isProfessionalModalVisible, setIsProfessionalModalVisible] = useState(false);
    const [user, setUser] = useState({email:"", firstName:"", lastName:""})
    const [role, setRole] = useState(null)
    const [searchProf, setSearchProf] = useState("")
    const [emailError, setEmailError] = useState(false)
    const { Option } = Select;

    const { data: contactData, error: contactError, isLoading: creatingProfessional, dispatchFetch: createProfessional, } = useAxios({ method: "POST", url: CONTACT});

    const {data: allContactData, isLoading:loadingAllContacts, dispatchFetch: fetchAllContactData,} = useAxios({ method: "GET", url: CONTACT + '/all', params: { asList: 1} });

    const {data: allPatientData, isLoading:loadingAllPatients, dispatchFetch: fetchAllPatientData,} = useAxios({ method: "GET", url: PATIENT_INFO + '/all', params: { asList: 1} });

    const { data: oauthData, error: oauthError, isLoading: loadingOauth, dispatchFetch: createOauth, } = useAxios({ method: "POST", url: ADD_OAUTH});

    const {data: allExamData, isLoading:loadingAllExams, dispatchFetch: fetchAllExamData,} = useAxios({ method: "GET", url: EXAM + '/all', params: { asList: 1} });

    const {data: loginHistoryData, isLoading:loadingLoginHistory, dispatchFetch: fetchAllLoginHistory,} = useAxios({ method: "GET", url: LOGIN_HISTORY+ '/all?orderby=createdDate&desc', params: { asList: 1} });

    const healthProfessionals = allContactData?.data?.data.filter((item) => item.HIPAA_Contact_Role === "User")
    const patientUsers = allPatientData?.data?.data.filter((item) => item.Specialist_1 !== null)

    const showProfessionalModal = () => {
        setIsProfessionalModalVisible(true);
    };


    const handleProfessionalCancel = () => {
        setIsProfessionalModalVisible(false);
        setUser({email:"", firstName:"", lastName:""})
        setRole(null)
    };


    const handleChange = (e) => {
         // check for valid email
         if(e.target.name === "email"){
            const email = e.target.value
            const emailRegex = /\S+@\S+\.\S+/
            if(!emailRegex.test(email)){
                setEmailError(true)
            } else{
                setEmailError(false)
            }
        }
        const name = e.target.name
        const value = e.target.value
        setUser({
            ...user,
            [name]:value.trim()
        })
    }

    const handleSelect = (value) => {
        setRole(value)
    }

    // Search Professional
    const handleProfessionalSearch = () => {
        const searchedProfessionalData = healthProfessionals.filter((prof) => {
            return prof?.Full_Name?.toLowerCase().includes(searchProf.toLowerCase()) || 
            prof?.Email_Address?.toLowerCase().includes(searchProf.toLowerCase())
        })
        return searchedProfessionalData
    }
   
    // Create and Invite health professional
    const inviteProfessional = () => {
        isAdmin ?
        (user.email ? createProfessional([
            {
                id: "",
                data: {
                    Name: user.lastName,
                    Email_Address: user.email,
                    First_Name: user.firstName,
                    HIPAA_Contact_Role: role === "Organization Admin" ? "Admin" : "User",
                    Individual_Active: 1,
                    Organization: patientInfo?.Organization,
                    User_Type: "Regular User",
                    Title: role,
                    Send_Email_to_User: "false"
                }
            }
        ]): message.error("Enter professional's email address") ):
        message.error("Only admins can create professionals")
    }

    // Generate password for professional contact
    useEffect(() => {
        if(contactData?.data[0]?.status === "success"){
            createOauth({
                id: contactData?.data[0]?.data?.id,
                data: {}
            })
            message.success("Health professional invited")
            setIsProfessionalModalVisible(false)
        }
        contactError && message.error(contactError)
    }, [contactData, contactError])


    // Send Health Professional Email
    useEffect(() => {
        if(oauthData?.data?.status === "success"){
            dispatchNotification({
                full_name:`${user.firstName} ${user.lastName}`,
                website_url:`${process.env.REACT_APP_WEBSITE_URL}/admin/login`,
                password:oauthData?.data?.data.password
            }, user.email, process.env.REACT_APP_ADMIN_SENDGRID_TEMPLATE_ID)
            fetchAllContactData()
            fetchAllExamData()
            fetchAllPatientData()
            fetchAllLoginHistory()

        }
    }, [oauthData])

    useEffect(() => {
        if(notificationData || notificationError){
            setUser({email:"", firstName:"", lastName:""})
            setRole(null)
        }
    }, [notificationData, notificationError])

    const healthProfessionalsColumns = [
        {
            title: 'Full Name',
            dataIndex: 'Full_Name',
            key: 'Full_Name'
        },
        {
            title: 'Email',
            dataIndex: 'Email_Address',
            key: 'Email_Address',
        },
        {
            title: 'Role',
            dataIndex: 'Title',
            key: 'Title',
        },
        {
            title: 'Patients',
            dataIndex: 'patient-count',
            key: 'patient-count',
            render:(_, record) => {
                const patientUsers = allPatientData?.data?.data?.filter((item) => item.Specialist_1 === record.id)
                return (
                <Text>{patientUsers?.length}</Text>
            )}
        },
        {
            title: 'Exams',
            dataIndex: 'exam-count',
            key: 'exam-count',
            render:(_, record) => {
                const examByProfessional = allExamData?.data?.data?.filter((item) => item.Assigned_Professional === record.id)
                return (
                <Text>{examByProfessional?.length}</Text>
            )}
        },
        {
            title:'Last Active',
            dataIndex: 'last-active',
            key: 'last-active',
            render:(_, record) => {
                const loginUsers = loginHistoryData?.data?.data?.find((item) => item.hipaaContactId === record.id)
                return (
                   <Text>{loginUsers === undefined ? "N/A" : moment(loginUsers?.createdDate).format('MMM Do YYYY, h:mm:ss a')}</Text>
                )}
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render:(_, record) => (
                <Tag color={status_codes[record?.Individual_Active]}>{record?.Individual_Active === "true" ? "Active" : "Inactive"}</Tag>
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render:(_, record) => (
                <ResetContactPassword  fetchAllContactData={fetchAllContactData} fetchAllExamData={fetchAllExamData} fetchAllPatientData={fetchAllPatientData} fetchAllLoginHistory={fetchAllLoginHistory} record={record}/>
            )
        },
    ];


    useEffect(() => {
        fetchAllContactData();
        fetchAllPatientData();
        fetchAllExamData();
        fetchAllLoginHistory();
    },[])

   
    if(loadingAllContacts || loadingAllPatients || loadingAllExams) return <LoadingState text="Fetching health professionals' data"/>

    return (
        <>
            <Row>
                <Col lg={24}>
                    <div className={styles.org_header}>
                        <h2>Health Professionals</h2>
                        <Space size={[8, 8]} wrap>
                            <SearchInput title="Professional" searchTerm={searchProf} setSearchTerm={setSearchProf} handleSearch={handleProfessionalSearch}/>
                            <Button className={`--outline-entry ${styles.org_btn}`} onClick={showProfessionalModal}>Invite Professional</Button>
                        </Space>
                    </div>
                    <Table dataSource={searchProf ? handleProfessionalSearch() : healthProfessionals} columns={healthProfessionalsColumns} loading={loadingAllContacts || loadingOauth} scroll={{ x: 800 }}/>
                </Col>
            </Row>
            <ModalComponent title="Invite Health Professionals" isModalVisible={isProfessionalModalVisible} handleCancel={handleProfessionalCancel}>
                <div className={styles.invite_email_form}>
                    <div style={{marginBottom:"20px"}}>
                        <Input className="input-secondary" name="email" value={user?.email} onChange={handleChange} placeholder=" Professional's Email" />
                        {
                            emailError && <p className={styles.email_error}>Enter a valid email address!</p>
                        }
                    </div>
                    <Input className="input-secondary" name="firstName" value={user?.firstName} onChange={handleChange} placeholder=" Professional's First Name" style={{marginBottom:"20px"}}/>
                    <Input className="input-secondary" name="lastName" value={user?.lastName} onChange={handleChange} placeholder=" Professional's Last Name" style={{marginBottom:"20px"}}/>
                    <Select  placeholder="Select Role" className='select-primary select-secondary' value={role}  style={{ width: '100%'}} onChange={handleSelect}>
                        { 
                            ROLES.map((role) => (
                                <Option value={role.value} key={role.id}>{role.value}</Option>
                            ))
                        }
                    </Select>
                    <Button 
                        type="primary" 
                        className='--card-button-active' 
                        style={{marginTop:"20px"}} 
                        onClick={inviteProfessional}
                        loading={creatingProfessional}
                        disabled={emailError || !user.email || !user.firstName || !user.lastName || !role}
                    >INVITE</Button>
                </div>
            </ModalComponent>
        </>
    )
}

export default OrganizationProfessionals


const ResetContactPassword = ({record, fetchAllContactData, fetchAllExamData, fetchAllLoginHistory, fetchAllPatientData, role, user}) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const { data: contactData, error: contactError, isLoading: deactivatingProfessional, dispatchFetch: handleProfessionalStatus } = useAxios({ method: "POST", url: CONTACT});

    const deactivateProfessionalAccount = () => {
       handleProfessionalStatus([
            {
                id: record.id,
                data: {
                    Individual_Active: 0,
                }
            }
        ])
    }

    const activateProfessionalAccount = () => {
        handleProfessionalStatus([
             {
                 id: record.id,
                 data: {
                     Individual_Active: 1,
                 }
             }
         ])
    }



     // Modal
     const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if(contactData?.data[0]?.status === "success"){          
            setShowConfirmModal(false)
            fetchAllContactData()
            fetchAllExamData()
            fetchAllPatientData()
            fetchAllLoginHistory()
            if(record?.Individual_Active === "true"){
                message.success(`${record?.Full_Name}'s account deactivated successfully`)
            }else{
                message.success(`${record.Full_Name}'s account activated successfully`)
            }
        }
        if(contactError){
            message.error("Error updating professional's status")
        }
    }, [contactData, contactError])

    return(
        <>
            <Space size={15}>
                <Button className='--table-btn' onClick={showModal}>
                    Reset Password
                </Button>
                <div style={{width:1.5, height:30, backgroundColor:"#D9D9D9"}}></div>
               { 
                    record?.Individual_Active === "true" ?
                    <Button className='--table-btn-danger' onClick={() => setShowConfirmModal(true)}>
                        Deactivate
                    </Button>
                    :
                    <Button className='--table-btn' onClick={() => setShowConfirmModal(true)}>
                        Activate
                    </Button>
                }
            </Space>
            <ModalComponent title="Reset Password" isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel}>
            <ResetPassword setIsModalVisible={setIsModalVisible} contact_id={record.id} contactData={record}/>
            </ModalComponent>
            <Modal 
                title={`${record?.Individual_Active === "true" ? 'Deactivate' : 'Activate'} User`}
                visible={showConfirmModal} 
                okButtonProps={{style:{backgroundColor:"rgb(58, 77, 143)", color:"#fff", border:"none"}, loading: deactivatingProfessional}}
                onOk={() => {
                    if(record?.Individual_Active === "true"){
                        deactivateProfessionalAccount()
                    } else{
                        activateProfessionalAccount()
                    } 
                }}
                onCancel={() => setShowConfirmModal(false)}
                placement="center"
                >
                <div className={styles.confirm_deactivate}>
                    <h3>Are you sure you want to {record?.Individual_Active === "true" ? 'deactivate' : 'activate'} {record?.Full_Name}'s account?</h3>
                    {
                        record?.Individual_Active === "true" ?
                        <p>Deactivating this user will prevent them from accessing the platform.</p> :
                        <p>Activating this user will grant them access to the platform.</p>
                    }
                </div>
            </Modal>
        </>
    )
}
