import React from 'react'
import { useLocation } from 'react-router-dom';
import Nav from '../Nav'
import Container from '../Container'
import { Outlet } from 'react-router'
import Footer from '../Footer'
import WelcomeBanner from '../Admin/WelcomeBanner'
import useAuth from '../../hooks/useAuth';

const Layout = ({header}) => {
    const { pathname } = useLocation();
    const { isAuth } = useAuth()
    return (
        <div>
            <Nav/>  
            { 
                isAuth && <WelcomeBanner />
            }
            <Container>
               { 
               header && 
                <div className='header'>
                    <h1>{header}</h1> 
                </div>
               }
               <div className='content'>
                    <Outlet />
               </div>
            </Container>
            <Footer />
        </div>
    )
}

export default Layout
