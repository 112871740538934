import { Layout } from 'antd'
import React from 'react'
import styles from './index.module.css'

const Wrapper = ({header, children}) => {
    return (
        <div className={styles._}>
            <div className={styles.header}>
                <h2>{header}</h2>
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}

export default Wrapper
