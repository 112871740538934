import { Button, message, Result, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { useSelector } from 'react-redux';
import useAxios from '../../hooks/useAxios';
import { EXAM_LIST } from '../../utils/examdata';
import DashboardCard from '../DashboardCard';
import styles from './index.module.css'
import LoadingState from '../LoadingState';
import { useDispatch } from 'react-redux';
import { LOAD_EXAM } from '../../redux/actions/types';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { paths } from '../../routes/paths';

const PatientDashboard = () => {
    
    let dispatch = useDispatch()
    let navigate = useNavigate()
    const { dispatchSinglePatientData, isRegistered } = useAuth()
    const { patientID, patientInfo } = useSelector(state => state.auth);
    const {exam_info} = useSelector(state => state.exam)


    const [isPatientInfoExist, setIsPatientInfoExist] = useState()
    const [isExamComplete, setIsExamComplete] = useState()
    const [isExamExpired, setIsExamExpired] = useState()
    const [patientExam, setPatientExam] = useState()

    const firstExam = EXAM_LIST[0].title
    const [examRequest, setExamRequest] = useState({
        Completed: "false",
        Date_Started: moment().format(),
        Progress: firstExam,
        Patient_Info: patientID,
        Organization_Info : patientInfo?.Organization,
        Assigned_Professional: patientInfo?.Specialist_1
    })

    // Fetch all exam objects
    const { data: examData, error, isLoading, dispatchFetch: fetchExamData } = useAxios({ method: "GET", url: `/index.php/exam/all?asList=1`});

    const { data: postExamData, error: postExamError, isLoading: submitting, dispatchFetch: submitExam } = useAxios({ method: "POST", url: `/index.php/exam`});

    useEffect(() => {
        fetchExamData()
     }, [])
 
  

    // Function to check user eligibility to access exams
    const checkExam = () => {

        // 24 hours in milliseconds
        const onehour = 3600 * 1000
        // Current date
        const dateNow = moment()

        const findPatientExam = examData?.data?.data.find((item) => item?.Patient_Info === patientID )

        const checkPatientExist = examData?.data?.data.some((item) => item?.Patient_Info === patientID )
        const checkCompletedExam = findPatientExam?.Completed === "true" 
        const checkExpiredExam = Math.round((dateNow.diff(moment(new Date(findPatientExam?.Date_Started))))/onehour) > 24;

        setIsPatientInfoExist(checkPatientExist)
        setIsExamComplete(checkCompletedExam)
        setIsExamExpired(checkExpiredExam)
        setPatientExam(findPatientExam)
    }

    // If Patient is not registered properly, reroute to patient info page
    useEffect(() => {
        if(!isRegistered){
            navigate(paths.PATIENT_INFO)
        }
    }, [])

    useEffect(() => {
        dispatchSinglePatientData()
    }, [])

    useEffect(() => {
        // (patientExam) && localStorage.setItem('awv_examInfo', JSON.stringify(patientExam));
        dispatch({
            type: LOAD_EXAM,
            payload: patientExam,
        })
    }, [examData, patientExam])


    useEffect(() => {
        checkExam()
    })
      

    useEffect(() => {
        if(isPatientInfoExist === false || (isExamComplete === false && isExamExpired === true)){
            const data = examRequest
            const body = [
                {
                    "id": "",
                    data
                }
            ]
            submitExam(body)
        }
    
    },[isPatientInfoExist])

    useEffect(() => {
        if(postExamData?.data[0].status ==='success'){
            message.success( postExamData?.data[0].message)
            fetchExamData()
        }        
    }, [postExamData])

    if(submitting){
        return(
            <LoadingState text="Creating Exam for you..."/>
        )
    }

    if(postExamError){
        return(
            <div>
                <h1>Error Occured while creating exam</h1>
                <p>{postExamError}</p>
            </div>
        )
    }

    if(isExamComplete === true){
        return (
            <div className="result_container">   
                <Result
                    status="success"
                    title="You've successfully completed your Exams"
                    subTitle="Go to the summary page to check out your result as well as neccssary medical suggestions"
                    extra={[
                    <Button type="primary" style={{width:"200px"}} className='button --card-button --card-button-active' onClick={() => navigate('/results')}>
                        Results Summary
                    </Button>,
                    <Button type="primary" style={{width:"200px"}} className='button --card-button --card-button-active' onClick={() => navigate(paths.PATIENT_INFO)}>
                    Visit Profile
                    </Button>,
                    ]}
                />
            </div>
        )
    }    

    return (
        <div className={styles._}>
           <h1 className={styles.header}>The Annual Wellness Exam</h1>
           {
            isLoading ? <LoadingState text="Loading..."/> :
                <Row 
                    gutter={[24, 24]} 
                    className={styles.exam_list} 
                    justify="center"
                >
                    {
                        EXAM_LIST.filter((item) => !item.optional).map((exam) => (
                            <DashboardCard 
                                key={exam.id} 
                                title={exam.title} 
                                image={exam.imageUrl} 
                                description={exam.description}
                                link={exam.link}
                                progress={ exam_info?.Progress || examRequest?.Progress }
                                loading={isLoading}
                            />
                        ))
                    }
                </Row>
            }
        </div>
    )
}

export default PatientDashboard
