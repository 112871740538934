import { Form, Input } from 'antd';
import React, { useState } from 'react'
import styles from './index.module.css'

const WeightCalculator = () => {

    const [prevWeight, setPrevWeight] = useState();
    const [weightLoss, setWeightLoss] = useState();

    const perLoss = (( 1- (prevWeight - weightLoss)/prevWeight)) * 100

    return (
        <div className={styles._}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <p>Weight % Calculator</p>
                </div>
                <form action="">

                    <div>
                        <p>Total Previous Weight</p>
                        <input
                            className={`input ${styles.weight_input}`}
                            style={{height:"40px", width:"100%"}}
                            label="Total Previous Weight"
                            placeholder="Type here..."
                            name="prevWeight"
                            value={prevWeight}
                            onChange={(e) => setPrevWeight(e.target.value)}
                        />
                    </div>
                    <div>
                        <p>Weight Loss</p>
                        <input 
                            className={`input ${styles.weight_input}`}
                            style={{height:"40px", width:"100%"}}
                            label="Weight Loss"
                            placeholder="Type here..."
                            name="weightLoss"
                            value={weightLoss}
                            onChange={(e) => setWeightLoss(e.target.value)}
                            />
                    </div>
                </form>
                <div className={styles.result}>
                    <p>% Loss:</p>
                    <p>{+perLoss.toFixed(2) || 0}%</p>
                </div>
            </div>
        </div>
    )
}

export default WeightCalculator
