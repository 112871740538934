import React from 'react';
import styles from './index.module.css'

const OptionImage = ({src, alt}) => {
  return (
    <div className={styles.option_img}>
        <img src={src} alt={alt} />
    </div>
  )
};

export default OptionImage;
