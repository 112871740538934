import React, { useEffect } from 'react';
import { Outlet, Navigate } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { message } from 'antd';


const  AdminPrivateRoute = () => {
    const { isAdminAuth } = useAuth()
    const { fetchResourceData, resourceData, logout } = useAuth()

    useEffect(()=> {
        fetchResourceData()
    }, [])

    // Log user out when token expires
    useEffect(() => {
        if(resourceData?.data?.success === false){
            logout()
            message.error("Your session has expired")
        }
    }, [resourceData])

    
    return isAdminAuth ? <Outlet /> : <Navigate to="/admin/login" />
};

export default AdminPrivateRoute;