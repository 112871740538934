import { Col, Row, Form, Input, Button, DatePicker, Select, message  } from 'antd'
import React, { useEffect } from 'react'
import Wrapper from '../../Registration/Wrapper'
import old_people from '../../../assets/images/old_people.png'
import styles from './index.module.css'
import useAxios from '../../../hooks/useAxios'
import { CONTACT, PROCLE_USER } from '../../../utils/endpoints'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment';
import useAuth from '../../../hooks/useAuth'
import { ROLES } from '../../../utils/roles'

const AdminProfile = () => {

    let navigate = useNavigate()

    const { patientID, patientInfo } = useSelector(state => state.auth); 
    const { isAdmin, isProfessional, isPasswordUpdated, isAdminUpdated, dispatchContactData } = useAuth()

    const { data: profileData, error: profileError, isLoading: updating, dispatchFetch: updateProfile } = useAxios({ method: "POST", url: CONTACT});

    const { data: procleUserData, error: procleUserError, isLoading: creatingProcleUser, dispatchFetch: createProcleUser, } = useAxios({ method: "POST", url: PROCLE_USER});

    const {data: procleUpdatedUserData, error: procleUpdatedUserError, isLoading: updatingProcleUser, dispatchFetch: updateProcleUser} = useAxios({  method: "POST", url: `${PROCLE_USER}?cc=+1&phone=${patientInfo?.Phone_Number}`});

    const onFinish = (values) => {
        // console.log('Success:', values);
        const body = [
            {
                "id": patientID,
                "data": {
                    ...delete values.prefix,
                    ...values,
                }
            }
        ]
        updateProfile(body)

        const updateProcleBody = {
            orgId: process.env.REACT_APP_PROCLE_ORG_ID,
            hospitalId: process.env.REACT_APP_PROCLE_HOSPITAL_ID,
            prefix:values.Sex.toLowerCase() === 'male' ? 'Mr' : 'Mrs',
            firstName:values.First_Name,
            lastName:values.Name,
            mobileNumber:values.Phone_Number,
            dateOfBirth:values.Birth_Date.format('YYYY-MM-DD'),
            userTimezone:"Central Standard Time (CST)",
            userRoleNames:[patientInfo?.Title],
            countryPhoneNoCode:"+1"
        }

        const createProcleBody = {
            email:values.Email_Address,
            ...updateProcleBody
        }

        !isAdminUpdated ?  createProcleUser(createProcleBody) :  updateProcleUser(updateProcleBody)
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const dateFormat = 'MM/DD/YYYY';
    const { Option } = Select;

    const prefixSelector = (
        <Form.Item name="prefix" initialValue={'+1'} noStyle>
          <Select style={{ width: 70}}>
            <Option value="+1">+1</Option>
          </Select>
        </Form.Item>
    )

    useEffect(() => {
        dispatchContactData()
    },[])

    useEffect(() => {
        if (profileData) {
            message.success('Profile Updated Successfully')
            if(isAdmin){
                navigate('/admin/organization')
            }
            if(isProfessional){
                navigate('/admin/professional')
            }
        }

        if(profileError){
            // message.error("An error occured")
            console.log(profileError)
        }

    }, [profileData, profileError]);

    return (
        <Wrapper header={ isAdminUpdated ? "Edit your profile" : "Update your profile"}>
            <Row style={{ padding:"0 30px"}} gutter={{ xs: 8, sm: 16, md: 24, lg: 110 }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form
                        name="basic"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout='vertical'
                        className={styles.form_container}
                    >
                            <Form.Item
                                label={<label className={styles.input_label}>First Name</label>}
                                name="First_Name"
                                className="label-sty"
                                required={false}
                                initialValue = {patientInfo?.First_Name !== "null" ? patientInfo?.First_Name : ""}
                                rules={[
                                    { 
                                        required: true, 
                                        message: 'Please input your first name' 
                                    },
                                    {
                                        pattern: /^[A-Za-z]*$/,
                                        message: 'Numbers, spaces and special characters are not allowed'
                                    }
                            ]}
                            >
                                <Input  className="input" placeholder="Type here..."/>
                            </Form.Item>
                            <Form.Item
                                label={<label className={styles.input_label}>Last Name</label>}
                                name="Name"
                                required={false}
                                initialValue = {patientInfo?.Name && patientInfo?.Name}
                                rules={[
                                    { 
                                        required: true, 
                                        message: 'Please input your last name' },
                                    {
                                        pattern: /^[A-Za-z]*$/,
                                        message: 'Numbers, spaces and special characters are not allowed'
                                    }
                                ]}
                            >
                                <Input className="input" placeholder="Type here..."/>
                            </Form.Item>
                            <Form.Item
                                label={<label className={styles.input_label}>Email</label>}
                                name="Email_Address"
                                required={false}
                                initialValue = {patientInfo?.Email_Address && patientInfo?.Email_Address}
                                rules={[{ type: 'email', required: true, message: 'Please input your email' }]}
                            >
                                <Input className="input" placeholder="Type here..." disabled/>
                            </Form.Item>
                            <Form.Item
                                label={<label className={styles.input_label}>Phone Number</label>}
                                name="Phone_Number"
                                required={false}
                                initialValue = {patientInfo?.Phone_Number && patientInfo?.Phone_Number}
                                rules={[
                                    { 
                                        required: true, 
                                        message: 'Please input your phone number!',
                                    },
                                    { 
                                        min: 10, 
                                        message: 'Phone number must be 10 digits' 
                                    },
                                    { 
                                        pattern: new RegExp(/^[0-9\b]+$/), 
                                        message: 'Please input a valid phone number!'
                                    }
                                ]}
                            >
                                <Input 
                                    addonBefore={prefixSelector} 
                                    maxLength={10}
                                    className='phone-input' 
                                />
                            </Form.Item>
                            {/* <Form.Item
                                label={<label className={styles.input_label}>Role</label>}
                                name="Role"
                                required={false}
                                initialValue = {patientInfo?.Title && patientInfo?.Title}
                                rules={[{ required: false, message: 'Please select your role' }]}
                            >
                                <Select
                                    placeholder="Select your role"
                                    className='select-primary'
                                    allowClear
                                    disabled={patientInfo?.Title?.toLowerCase() === 'organization admin'}
                                >
                                    {
                                        ROLES.map((role) => (
                                            <Option key={role.id} value={role.value}>{role.value}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item> */}
                            <Form.Item 
                                label={<label className={styles.input_label}>Date of Birth</label>}
                                name="Birth_Date"
                                required={false}
                                initialValue={patientInfo?.Birth_Date &&  moment(`${patientInfo?.Birth_Date.replaceAll('-', '/')}`, 'YYYY/MM/DD')}
                                rules={[{ required: true, message: 'Please input your date of birth' }]}
                            >
                                <DatePicker  
                                    className="input" 
                                    placeholder="MM/DD/YYYY" 
                                    format={dateFormat}
                                    disabledDate={(current) => {
                                        return current && current > moment().endOf('day');
                                    }}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<label className={styles.input_label}>Sex</label>}
                                name="Sex"
                                required={false}
                                initialValue = {patientInfo?.Sex && patientInfo?.Sex}
                                rules={[{ required: true, message: 'Please input your sex' }]}
                            >
                                <Select
                                    placeholder="Select your sex"
                                    className='select-primary'
                                    allowClear
                                >
                                    <Option value="male">Male</Option>
                                    <Option value="female">Female</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Button type="primary" style={{marginTop:'12px'}} htmlType="submit" className="button --primary" loading={updating}>
                                    Submit
                                </Button>
                            </Form.Item>
                    </Form>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.img_section}>
                        <img src={old_people} alt="old man and woman hugging" />
                    </div>
                </Col>
            </Row>
        </Wrapper>
    )
}

export default AdminProfile
