import React from 'react'
import PatientDashboard from '../components/PatientDashboard'

const Dashboard = () => {
    return (
        <>
            <PatientDashboard />
        </>
    )
}

export default Dashboard
