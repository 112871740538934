import React from 'react'
import styles from './index.module.css'

const ExamLayout = ({title, index, children}) => {

    const updateTitle = () => {
        // if(title === 'weight-survey' || title === 'eat-survey'){
        //     return 'snaq test'
        // }
        // if(title === 'f3alls'){
        //     return 'sarcf test'
        // }
        // if(title === 'hearing-survey'){
        //     return 'rcs test'
        // }
        if(title === 'iadls'){
            return 'Instrumental Activities of Daily Living'
        }
        return title + ' test'
    }

    const updateSubTitle = () => {
        if(title === 'adls' && index > 7){
            return '(KATZ Activities of Daily Living)'
        }
    }

    return (
        <div className={styles._}>
            <h1>{updateTitle()}</h1>
            <p className={styles.sub_title}>{(updateSubTitle())}</p>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}

export default ExamLayout
