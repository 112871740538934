import { Card, Col, Row } from 'antd';
import React from 'react'
import { useEffect } from 'react';
import useAxios from '../../../hooks/useAxios';
import { CONTACT, EXAM, PATIENT_INFO } from '../../../utils/endpoints';
import { Column } from '@ant-design/plots';
import styles from './index.module.css'
import LoadingState from '../../LoadingState';
import StatisticsCard from '../StatisticsCard';

const OrganizationStatistics = () => {

    const {data: allContactData, isLoading: loadingAllContacts, dispatchFetch: fetchAllContactData,} = useAxios({ method: "GET", url: CONTACT + '/all', params: { asList: 1} });

    const {data: allPatientData, isLoading: loadingAllPatients, dispatchFetch: fetchAllPatientData,} = useAxios({ method: "GET", url: PATIENT_INFO + '/all', params: { asList: 1} });

    const {data: allExamData, isLoading: loadingAllExams, dispatchFetch: fetchAllExamData,} = useAxios({ method: "GET", url: EXAM + '/all', params: { asList: 1} });

    const { data: lifeadData, dispatchFetch: fetchLifead } = useAxios({ method: "GET", url: `/index.php/lifead/all?asList=1`});
    const { data: aloneData, dispatchFetch: fetchAlone } = useAxios({ method: "GET", url: `/index.php/alone/all?asList=1`});
    const { data: energyData, dispatchFetch: fetchEnergy } = useAxios({ method: "GET", url: `/index.php/energy/all?asList=1`});
    const { data: amsadData, dispatchFetch: fetchAmsad } = useAxios({ method: "GET", url: `/index.php/amsad/all?asList=1`});
    const { data: adlsData, dispatchFetch: fetchAdls } = useAxios({ method: "GET", url: `/index.php/adls/all?asList=1`});
    const { data: iadlsData, dispatchFetch: fetchIadls } = useAxios({ method: "GET", url: `/index.php/iadls/all?asList=1`});
    const { data: frailNhData, dispatchFetch: fetchFrailNh } = useAxios({ method: "GET", url: `/index.php/frail-nh/all?asList=1`});
    const { data: frailData, dispatchFetch: fetchFrail } = useAxios({ method: "GET", url: `/index.php/frail/all?asList=1`});
    const { data: snaqData, dispatchFetch: fetchSnaq } = useAxios({ method: "GET", url: `/index.php/snaq/all?asList=1`});
    const { data: eatSurveyData, dispatchFetch: fetchEatSurvey } = useAxios({ method: "GET", url: `/index.php/eat-survey/all?asList=1`});
    const { data: rcsData, dispatchFetch: fetchRcs } = useAxios({ method: "GET", url: `/index.php/rcs/all?asList=1`});
    const { data: hearingSurveyData, dispatchFetch: fetchHearingSurvey } = useAxios({ method: "GET", url: `/index.php/hearing-survey/all?asList=1`});
    const { data: sarcfData, dispatchFetch: fetchSarcf } = useAxios({ method: "GET", url: `/index.php/sarcf/all?asList=1`});
    const { data: adamData, dispatchFetch: fetchAdam } = useAxios({ method: "GET", url: `/index.php/adam/all?asList=1`});

    const completedExams = allExamData?.data?.data.filter((exam) => exam.Completed === "true")

    const calculateScoreAverage = (testData) => {
        const filteredTestData = testData?.data?.data?.filter((item) => item.Score != null)
        const scoreTotal = filteredTestData?.reduce((acc, curr) => {
            const sum = acc + parseInt(curr?.Score)
            return sum
        }, 0)

        const average = (scoreTotal/(filteredTestData?.length)).toFixed(2)
        if(average === NaN){
            return <p>--</p>
        }
        return Number(average)
    }

    const data = [
        {
            exam:"LIFEAD",
            score: calculateScoreAverage(lifeadData)
        },
        {
            exam:"ALONE",
            score: calculateScoreAverage(aloneData)
        },
        {
            exam:"ENERGY",
            score: calculateScoreAverage(energyData)
        },
        {
            exam:"AMSAD",
            score: calculateScoreAverage(amsadData)
        },
        {
            exam:"ADLS",
            score: calculateScoreAverage(adlsData)
        },
        {
            exam:"IADLS",
            score: calculateScoreAverage(iadlsData)
        },
        {
            exam:"FRAIL-NH",
            score: calculateScoreAverage(frailNhData)
        },
        {
            exam:"FRAIL",
            score: calculateScoreAverage(frailData)
        },
        {
            exam:"SNAQ",
            score: calculateScoreAverage(snaqData)
        },
        {
            exam:"EAT",
            score: calculateScoreAverage(eatSurveyData)
        },
        {
            exam:"RCS",
            score: calculateScoreAverage(rcsData)
        },
        {
            exam:"HEARING",
            score: calculateScoreAverage(hearingSurveyData)
        },
        {
            exam:"SARCF",
            score: calculateScoreAverage(sarcfData)
        },
        {
            exam:"ADAM",
            score: calculateScoreAverage(adamData)
        }
    ]

    const config = {
        data,
        padding: 'auto',
        height:500,
        xField: 'exam',
        yField: 'score',
        color: '#464F6E',
        label: {
            position: 'middle',
            style: {
              fill: '#FFFFFF',
              opacity: 0.6,
            },
          },
        xAxis: {
          // type: 'timeCat',
        },
    };
    

    useEffect(() => {
        fetchAllContactData()
        fetchAllPatientData()
        fetchAllExamData()
        fetchLifead()
        fetchAlone()
        fetchEnergy()
        fetchAmsad()
        fetchAdls()
        fetchIadls()
        fetchFrailNh()
        fetchFrail()
        fetchSnaq()
        fetchEatSurvey()
        fetchRcs()
        fetchHearingSurvey()
        fetchSarcf()
        fetchAdam()
    }, [])

    if(loadingAllExams || loadingAllPatients || loadingAllContacts){
        return <LoadingState text="Loading Stats. Please wait..."/>
    }

    

    return (
        <>
            <StatisticsCard allContactData={allContactData} allPatientData={allPatientData} allExamData={allExamData}/>
            <div className={styles.org_stats_container}>
                <h2>Exams Statistics</h2>
                <div className={styles.score_ave}>
                    <Column {...config} />
                    <p>Exams Score Averages</p>
                </div>
            </div>
        </>
    )
}

export default OrganizationStatistics