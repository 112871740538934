import { Button, Col, Row } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.css';

const ScoresPanel = ({content}) => {
    let navigate = useNavigate()

    return (
        <div className={styles.scores_container}>
            <Row justify="space-between" align="middle">
                <Col>
                   <p>{content}</p> 
                </Col>
                <Col>
                    <Button type="primary" style={{width:"115px"}} className='button --card-button-active' onClick={() => navigate(-1)}>
                        Back
                    </Button>
                </Col>
            </Row>
        </div>
    )
};

export default ScoresPanel;
