import { Button, Input, Space } from 'antd'
import React from 'react'
import { booleanOptions, clockHoursOptions, clockTimesOptions, inputTextOptions, naOption, nextOption } from '../../utils/optionsdata'
import MultiPickList from '../MultiPickList'
import MultipleInputOptions from '../MultipleInputOptions'
import SingleInputOption from '../SingleInputOption'
import SliderInput from '../SliderInput'
import TextAreaInput from '../TextAreaInput'
import styles from './index.module.css'

const QuestionOptions = ({type, questionName, type_value, checkSelectedOption, checkRcsSelectedOption, showNext,  index, examType, handleMultiPickListOption, nextQuestion, setAnswerResponse, setPoints, ...rest}) => {

    const picklistArray = []
    const multiPickListArray = []

    if(type === 'picklist'){
        let pickListOptions = type_value.split('||')
        for(const [index, elem] of pickListOptions.entries()){
            picklistArray.push({
                id:index,
                value: elem,
                name: elem
            })
        }
    }

    if(type === 'multipicklist'){
        let multiPickListOptions = type_value.split('||')
        for(const [index, elem] of multiPickListOptions.entries()){
            multiPickListArray.push({
                id:index,
                value: elem,
                name: elem
            })
        }
    }

    return(
        <div className={styles._} style={{ paddingTop: '40px'}}>
            {/* Boolean Options Type */}
            {
                type === 'boolean' && (
                    <Space size={[40, 20]} wrap>
                        {
                            booleanOptions.map((option, i) => {
                                return (
                                    <Button type="primary" className="button --primary"
                                        key={option.id}
                                        onClick={() => checkSelectedOption(option.value, i)}
                                    >
                                        {option.name}
                                    </Button>
                                )
                            })     
                        }
                        {
                            (index === 1 && examType === 'frail') && naOption.map((option, i) => {
                                return (
                                    <Button type="primary" className="button --primary"
                                        key={option.id}
                                        onClick={() => checkSelectedOption(option.value, i)}
                                    >
                                        {option.name}
                                    </Button>
                                )
                            })
                        }
                    </Space>
                )
            }

            {/* Picklist Option Type */}
            {
                type === 'picklist' && (
                    <Space size={[40, 20]} wrap>
                        {
                                picklistArray.map((option, i) => {
                                    return (
                                        <Button type="primary" className="button --primary"
                                            style={{width:"inherit", minWidth:'200px'}}
                                            key={option.id}
                                            onClick={() => checkSelectedOption(option.value, i)}
                                        >
                                            {option.name}
                                        </Button>
                                    )
                                })
                            }
                    </Space>
                )
            }

            {/* Clock Options Type */}
            {
                type === 'clock-hours-options' && (
                    <Space size={[10, 20]} wrap>
                    {
                        clockHoursOptions.map((option) => {
                            return(
                                <div className={styles.clock_container}  key={option.id} onClick={() => checkRcsSelectedOption(option.value, option.point)}>
                                    <Button type="primary" className={`button ${styles.clock_btn}`}>
                                        {option.name}
                                    </Button>
                                    <div className={styles.clock_img}>
                                        <img src={option.imageUrl} alt="" />
                                    </div>
                                </div>
                            )
                        })
                    }
                    </Space>
                )
            }
            {
                type === 'clock-times-options'  && (
                    <Space size={[10, 20]} wrap>
                    {
                        clockTimesOptions.map((option) => {
                            return(
                                <div className={styles.clock_container} onClick={() => checkRcsSelectedOption(option.value, option.point)}  key={option.id}>
                                    <Button type="primary" className={`button ${styles.clock_btn}`}>
                                        {option.name}
                                    </Button>
                                    <div className={styles.clock_img}>
                                        <img src={option.imageUrl} alt="" />
                                    </div>
                                </div>
                            )
                        })
                    }
                    </Space>
                )
            }

            {/* Multipicklist Option Type */}
            {
                type === 'multipicklist' && <MultiPickList multiPickListArray={multiPickListArray} diets={rest.diets} handleMultiPickListOption={handleMultiPickListOption} nextQuestion={nextQuestion} setAnswerResponse={setAnswerResponse} setPoints={setPoints} name={questionName}/>
            }

            {/* Show singular input text */}
            {
                type === 'single-input-option' && <SingleInputOption nextQuestion={nextQuestion} setAnswerResponse={setAnswerResponse} name={questionName} setPoints={setPoints}/>
            }

            {/* Multiple Input Text Option Type */}
            {
                type === 'multiple-input-options' && <MultipleInputOptions nextQuestion={nextQuestion} setAnswerResponse={setAnswerResponse} name={questionName} setPoints={setPoints}/>
            }


            {
                type === 'textarea' && <TextAreaInput nextQuestion={nextQuestion} setAnswerResponse={setAnswerResponse} name={questionName}/>
            }

            {
                (examType === 'adls' && index === 0) && 
                <SliderInput 
                    nextQuestion={nextQuestion} 
                    min={0} 
                    max={10} 
                    text="" 
                    type="pain"
                    setAnswerResponse={setAnswerResponse} 
                    name={questionName}
                />
            }
            {
                (examType === 'adls' && index === 1) && 
                <SliderInput 
                    nextQuestion={nextQuestion} 
                    min={0} 
                    max={30} 
                    text="Drinks" 
                    type="drinks"
                    setAnswerResponse={setAnswerResponse} 
                    name={questionName}
                />
            }
        </div>
  )
}

export default QuestionOptions
