import React from 'react'
import PatientInfo from '../components/Registration/PatientInfo'

const Info = () => {
    return (
        <>
            <PatientInfo />
        </>
    )
}

export default Info
