import { Button, Calendar, Col, message, Row, Space, Table, Typography } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useAxios from '../../../hooks/useAxios'
import Cta from '../Cta'
import moment from 'moment';
import styles from './index.module.css'
import ModalComponent from '../ModalComponent'
import { useState } from 'react'
import LoadingState from '../../LoadingState'
import { EXAM, PATIENT_INFO, PROCLE_GUEST_USER } from '../../../utils/endpoints'
import { useSelector } from 'react-redux'
import ReviewItem from './review_item'


const { Text } = Typography

const ExamReviews = () => {
    
    const {patientID} = useSelector(state => state.auth)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment().format('MM/DD/YYYY'))
    const [examId, setExamId] = useState()
    const professionalInfo = useSelector(state => state.auth)
    const navigate = useNavigate()


    const { data: allPatientData, isLoading: loadingAllPatientData, dispatchFetch: fetchAllPatientData } = useAxios({ method: "GET", url: PATIENT_INFO + '/all', params: { asList: 1}});
 
    const { data: scheduleUserProcleData, error: scheduleUserProcleError, isLoading: schedulingProcleUser, dispatchFetch: scheduleProcleUser, } = useAxios({ method: "POST", url: PROCLE_GUEST_USER});

    const { data: guestUserProcleData, error: guestUserProcleError, isLoading: viewingProcleSchedule, dispatchFetch: viewProcleSchedule, } = useAxios({ method: "POST", url: PROCLE_GUEST_USER});

    const { data: examData, error, isLoading, dispatchFetch: fetchExamData } = useAxios({ method: "GET", url: '/index.php/exam?asList=1&Completed=1&Reviewed=0'});

    const { data: postExamData, error: postExamError, isLoading: submitting, dispatchFetch: submitExam } = useAxios({ method: "POST", url: EXAM});

    const examsNeedingReview =  examData?.data?.data.filter((exam) => (exam?.Reviewed === "0" && exam?.Scheduled === null && exam?.Assigned_Professional === patientID))

    const showModal = (id) => {
        setIsModalVisible(true);
        setExamId(id)
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onPanelChange = (value, mode) => {
        console.log(value.format('MM/DD/YYYY'), mode);
    }

    const onSelect = (value) => {
        setSelectedDate(value.format('MM/DD/YYYY'))
    }

    const examColumns = [
        {
            title: 'Full Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                const patientData = allPatientData?.data?.data.find((patient) => patient.id === record?.Patient_Info)
                return (
                <Text>{(patientData?.First_Name && patientData?.Last_Name) ? `${patientData?.First_Name} ${patientData?.Last_Name}` : 'Patient'}</Text>
            )}
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (_, record) => {
                const patientData = allPatientData?.data?.data.find((patient) => patient.id === record?.Patient_Info)
                return (
                <Text>{patientData?.Email}</Text>
            )}
        },
        {
            title: 'Exam ID',
            dataIndex: 'exam-id',
            key: 'exam-id',
            render: (_, record) => (
                <Text>{record?.id || '--'}</Text>
            )
        },   
        {
            title: 'Exam Date',
            dataIndex: 'exam-date',
            key: 'exam-date',
            render: (_, record) => (
                <Text>{moment(`${record?.Date_Started}`).format('MMM Do YYYY')}</Text>
            )
        },   
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => {
                const patientData = allPatientData?.data?.data.find((patient) => patient.id === record?.Patient_Info)
                return(
                <Space size="middle">
                   <Button 
                    type="primary"
                    className={`button --outline ${styles.view_btn}`}
                    onClick={() => {
                        navigate(`/results?user=admin&exam=${record?.id}`, { state: patientData })
                    }}
                   >
                        View Results
                    </Button>
                    <Button 
                        loading={(examId === record?.id) && schedulingProcleUser}
                        className={`button --primary ${styles.schedule_btn}`}
                        onClick={() =>{ 
                            scheduleUser(patientData?.Phone)
                            setExamId(record?.id)
                        }} 
                        type="primary"
                        disabled
                        >
                            Schedule
                    </Button>
                </Space>
            )}
       }
    ];


    const createAppointment = () => {
        submitExam([
            {
                "id": examId,
                data: {
                    Scheduled: moment(selectedDate)
                }
            }
        ])
    }

    // View Schedules
    const viewSchedule = () => {
        viewProcleSchedule({
            cc:"+1",
            phone:professionalInfo?.patientInfo?.Phone_Number,
            patient_phone:""
        })
    }

    // Schedule patient
    const scheduleUser = (patientPhone) => {
    scheduleProcleUser({
            cc:"+1",
            phone:professionalInfo?.patientInfo?.Phone_Number,
            patient_phone:patientPhone
        })
    }

    useEffect(() => {
        if(scheduleUserProcleData?.data.status === "success"){
            window.open(`${scheduleUserProcleData?.data?.data}`);
        }
    }, [scheduleUserProcleData])


    useEffect(() => {
        if(guestUserProcleData?.data.status === "success"){
            window.open(`${guestUserProcleData?.data?.data}`);
        }
    }, [guestUserProcleData])

    useEffect(() => {
        fetchExamData()
        fetchAllPatientData()
     }, [])

    useEffect(() => {
        if(postExamData){
            setIsModalVisible(false)
            message.success("Patient Appointment Created")
            fetchExamData()
            window.location.reload()
        }
    }, [postExamData])

    if(submitting){
        return(
            <LoadingState text="creating appointment..."/>
        )
    }

    // if(examsNeedingReview && examsNeedingReview.length === 0){
    //     return(
    //         <h2 style={{textAlign:"center"}}>No Exam needs review</h2>
    //     )
    // }

    return (
        <>
            <div className={styles.review_container}>
                <div className={styles.review_header}>
                    <h2>Exams Needing Review</h2>
                    <Button 
                        className={`button --primary ${styles.schedule_btn}`} 
                        style={{borderRadius:4}} onClick={viewSchedule}
                        loading={viewingProcleSchedule}
                        disabled
                        >View All Appointments</Button>
                </div>
                <div className={styles.review_list}>
                <Table
                    dataSource={examsNeedingReview?.sort((a, b) => moment(b?.Date_Started).format('YYYYMMDD') - moment(a?.Date_Started).format('YYYYMMDD'))} 
                    columns={examColumns} 
                    loading={isLoading || loadingAllPatientData} 
                    scroll={{ x: 800 }}
                />
                </div>
            </div>
            <ModalComponent title="Create Patient Appointments" isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} width={1200}>
                <div className={styles.appointment_calendar}>
                    <div className={styles.create}>
                        <div className={styles.selected_date}>
                            {selectedDate}
                        </div>
                        <Button className='--card-button-active' style={{width:"200px"}} onClick={createAppointment}>Create</Button>
                    </div>
                    <Calendar  onPanelChange={onPanelChange} onSelect={onSelect}/>
                </div>
            </ModalComponent>
        </>
    )
}

export default ExamReviews