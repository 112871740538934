import React from 'react'
import OrderSheetContainer from '../../components/Admin/OrderSheet.js/index.js'

const OrderSheet = () => {
  return (
    <>
        <OrderSheetContainer />
    </>
  )
}

export default OrderSheet