export const PRIMARY_COLOR = "#324852"

export const getFullName = (firstName, lastName) => {
    return `${firstName} ${lastName}`;
};

// construct name format to be first name and last name initial
export const nameFormat = (name) => {
    if(!name) return ""
    if (!name.includes(" ")) {
        return name
    }
    let splitName = name.split(" ")
    return splitName[0] + " " + splitName[1][0] + "."
}

// Generate random password
export const generateRandomPassword = () => {
    let password = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
        password += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return password;
};