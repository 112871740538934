import { message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../redux/actions/types";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const useAxios = (
    config = {},
    reduxOptions = null
) => {

    const { token } = useSelector((state) => state.auth);
    const dispatch = useDispatch()

    const [response, setResponse] = useState(null)
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    // Set default axios config 
    let defaultConfig = {
        method: "GET",
        headers:{
            Accept: "application/json, text/plain, multipart/form-data, */*",
            "Content-Type": "application/json",
            ...(token && { Authorization: `Bearer ${token}` }),
        }
    }


    config = {...defaultConfig, ...config}
    
    // Remove Authorization Header from url that contains https://osha-metadata.herokuapp.com
    if(config.url.includes('/full_metadata.php')){
        config = {
            ...delete config.headers.Authorization,
            ...config
        }
    }

    const dispatchFetch = (body) => {
        setIsLoading(true)

        // Function to fetch data with Axios
        const fetchData = async () => {

            // check if body exists then add to config
            if(body){
                config = {
                    ...config,
                    data:body
                }
            }

            // Check to see if body is a URLSearchParams
            if(body instanceof URLSearchParams){
                config = {
                    ...config,
                    headers:{
                        ...config.headers,
                        "Content-Type":"application/x-www-form-urlencoded",
                    }
                }
            } else {
                config = {
                    ...config,
                    headers:{
                        ...config.headers,
                        "Content-Type": "application/json",
                    }
                }
            }

            try{
                const res = await axios.request(config)
                if(res.data && res.data[0]?.status !== "error"){
                    reduxOptions?.actionType && 
                        dispatch({
                            type: reduxOptions?.actionType,
                            payload: res.data
                        })
                    setResponse(res)
                    // console.log(res)
                } else{
                    setError(res?.data[0]?.message)
                    console.log(res.data[0].message)
                }
            } catch(error){
                setError(error)
                console.log(error)
            } finally{
                setIsLoading(false)
            }
        }

        fetchData()
        
    }

    let data = response

    return {data, error, isLoading, dispatchFetch}

}

export default useAxios

