import React from 'react'
import AdminProfile from '../../components/Admin/AdminProfile'

const Profile = () => {
  return (
    <>
       <AdminProfile />
    </>
  )
}

export default Profile