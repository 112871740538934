import { Col, Row,Form, Input, Button, message, Result, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import useAuth from '../../hooks/useAuth'
import { LOGIN, PATIENT_LOGIN } from '../../utils/endpoints'
import useAxios from '../../hooks/useAxios'
import { Navigate, useNavigate } from 'react-router'
import EntryContainer from '../EntryContainer'
import { paths } from '../../routes/paths'
import forge from 'node-forge';
import { useSelector } from 'react-redux'

const PatientEntry = () => {

    let navigate = useNavigate();

    const auth = useSelector(state => state.auth);
    const { isAuth, saveAuth, isRegistered, loadingPatientData, singlePatientError, dispatchSinglePatientData } = useAuth()

    const [readableId, setReadableId] = useState()
    const [email, setEmail] = useState()
    const [encToken, setEncToken] = useState()
    

    // const {
    //     data: loginData,
    //     error: loginError,
    //     isLoading: loggingIn,
    //     dispatchFetch: dispatchLogin,
    //   } = useAxios({ method: "POST", url: LOGIN});

      const { 
        data: loginData,
        error: loginError,
        isLoading: loggingIn,
        dispatchFetch: dispatchLogin,
    } = useAxios({ 
        method: "GET", 
        url: PATIENT_LOGIN, 
        headers: { Authorization: encToken } 
    });


    const onFinish = (values) => {
        setReadableId(values.readableId.trim())
        setEmail(values.email)
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    // Encrypting patient login authorization
    function encryptPatientLoginToken() {

        const unenctoken = JSON.stringify({
            "patient_code": readableId,
            "email": email,
            "admin_email": process.env.REACT_APP_USERNAME,
            "password": process.env.REACT_APP_PASSWORD
        });

        const SINGLE_LINE_KEY = process.env.REACT_APP_PUBLIC_API_KEY;
        const FORMATTED_KEY = SINGLE_LINE_KEY.replace(/-----BEGIN PUBLIC KEY-----|-----END PUBLIC KEY-----/g, '')
            .match(/.{1,64}/g).join('\n');
        const FINAL_KEY = `-----BEGIN PUBLIC KEY-----\n${FORMATTED_KEY}\n-----END PUBLIC KEY-----`;

        const publicKeyPem = FINAL_KEY;
        const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);

        const encrypted = publicKey.encrypt(unenctoken, 'RSA-OAEP', {
            md: forge.md.sha256.create()
        });

        const encodedToken = forge.util.encode64(encrypted);
        setEncToken(encodedToken);
    }

    useEffect(() => {
        if(readableId && email){
            encryptPatientLoginToken()
        }
    }, [readableId, email])

    useEffect(() => {
        if(encToken){
            dispatchLogin()
        }
    }, [encToken])

    useEffect(() => {
        if (loginData) {
            saveAuth({
                readableId: readableId,
                token: loginData?.data?.access_token,
            }); 
        }
    }, [loginData]);

    useEffect(() => {  
        if(auth?.readableId){
            dispatchSinglePatientData()    
        }
    },[auth])

    useEffect(() => {
       loginError && message.error("Invalid Email or Password")
    }, [loginError])

    // when id is not found
    useEffect(() => {
        if(singlePatientError){
            message.error("Invalid Email or Password")
        }
    }, [singlePatientError])

    useEffect(() => {
        (isAuth && isRegistered) && message.success("Logged In Successfully")
    }, [isAuth, isRegistered])

    useEffect(() => {
        (isAuth && !isRegistered) && message.success("Logged In Successfully")
    }, [isAuth])

    // when user is authenticated and registered
    if(isAuth && isRegistered){
        return(
            <Navigate to={paths.DASHBOARD}/>
        )
    }

    // when user is not registered
    if(isAuth && !isRegistered){
        return(
            <Navigate to={paths.PATIENT_INFO}/>
        )
    }
    

    return (
        <EntryContainer title="Patient Entry" onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Form.Item
                label="Enter Email Address"
                name="email"
                required={false}
                rules={[
                    { required: true, message: 'Please input your email address' },
                    { type: 'email', message: 'Please enter a valid email address' }
                ]}
            >
                <Input  className="input" placeholder="Email Address"/>
            </Form.Item>
            <Form.Item
                label="Enter Patient Code"
                name="readableId"
                required={false}
                rules={[{ required: true, message: 'Please input your patient code' }]}
            >
                <Input.Password className="input" placeholder="Patient Code"/>
            </Form.Item>
            <Form.Item wrapperCol={{ span: 24 }}>
                <Button type="primary" style={{marginTop:'12px'}} htmlType="submit" className="button --secondary" loading={loggingIn}>
                    LOG IN
                </Button>
            </Form.Item>
            <Form.Item>
                <p className={styles.admin_login} onClick={() => navigate('/admin/login')}>LOGIN AS ADMIN</p>
            </Form.Item>
        </EntryContainer>           
    )
}

export default PatientEntry
