import { Button, Slider, Tooltip } from 'antd';
import React from 'react';
import { useState } from 'react';
import NextButton from '../NextButton';
import styles from './index.module.css'
import './slider.css'

const SliderInput = ({min, max, text, type, nextQuestion, name, setAnswerResponse}) => {

    const [inputValue, setInputValue] = useState(0)

    const handleChange = (value) => {
        setInputValue(value)
    }

    const handleSubmit = () => {

        setAnswerResponse((prevAnswerResponse) => ({
            ...prevAnswerResponse,
            [name]:inputValue
        }))

        nextQuestion()
    }

    const getTitle = (type) => {
        switch(type){
            case "pain":
                return <>Rate your pain using the slider</>
            case "drinks":
                return <>Choose number of drinks using the slider</>   
            default:
                return <></>
        }
    }

    const getSliderColorCode = (value) => {
        if (value < 2) {
            return "#308A45";
        } else if (value >= 2 && value < 4) {
            return "#90BD31";
        } else if (value >= 4 && value < 6) {
            return "#F3F320";
        } else if (value >= 6 && value < 8) {
            return "#F2B10F";
        } else if (value >= 8 && value < 10) {
            return "#F37100";
        } else if (value === 10) {
            return "#d30100";
        } else {
            return "rgb(230, 230, 230)";
        }
    }

    return( 
        <div className={styles._}>
            <p className={styles.slider_curr_title}>{getTitle(type)}</p>
            <p className={styles.curr_val}>{inputValue} {text}</p>
            <div className={styles.container}>
                <p className={styles.value}>{min}</p> 
                <Tooltip 
                    placement="topLeft" 
                    title={"Slide here"} 
                    visible={inputValue === 0}
                >
                        <Slider
                            style={{width:'300px', height:'40px'}}
                            min={min}
                            max={max}
                            onChange={handleChange}
                            value={typeof inputValue === 'number' ? inputValue : 0}
                            handleStyle={{backgroundColor: type==="pain" ? getSliderColorCode(inputValue) : "rgb(230, 230, 230)", border: '1px solid rgb(170, 170, 170)', height:'45px', width:'20px', borderRadius:'5px'}}
                        />
                </Tooltip>
                <p className={styles.value}>{max}</p> 
            </div>
            <NextButton handleSubmit={handleSubmit}/>
        </div>
    )
};

export default SliderInput;
