import React, { useEffect } from 'react';
import { Outlet, Navigate } from "react-router-dom";
import useAuth from '../hooks/useAuth';
import { message } from 'antd';


const  PrivateRoute = () => {
    const { isAuth } = useAuth()
    const { fetchResourceData, resourceData, logout } = useAuth()

    useEffect(()=> {
      fetchResourceData()
    }, [])
  
     // Log user out when token expires
    useEffect(() => {
        if(resourceData?.data?.success === false){
            logout()
            message.error("Your session has expired")
        }
    }, [resourceData])
  

    return isAuth ? <Outlet /> : <Navigate to="/" />
};

export default PrivateRoute;