import { Col } from 'antd';
import React from 'react';
import styles from './index.module.css'

const SummaryCard = ({title, content, completed_date}) => {
  
    return(
        <Col>
            <div style={{padding:"0"}} className={styles.summary_card}>
                <div className={styles.title}>
                    <h4>{title}</h4>
                </div>
                <div className={styles.content}>
                    <p>{content}</p>
                </div>
                <div className={styles.bottom}>
                    {
                        completed_date &&
                        <>
                            <p>Completed:</p>
                            <p>02/21/2022</p>
                        </>
                    }
                </div>
            </div>
        </Col>
    )
};

export default SummaryCard;
