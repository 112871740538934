export const booleanOptions = [
    {
        id:1,
        value:"1",
        name: "Yes"
    },
    {
        id:2,
        value:"0",
        name: "No"
    },
]

export const naOption = [
    {
        id:3,
        value:"",
        name: "Not Available"
    }
]

export const nextOption = [
    {
        id:1,
        name:"Next",
        point:0
    }
]

export const clockHoursOptions = [
    {
        id:1,
        imageUrl: "https://s3.amazonaws.com/appforest_uf/f1598816485933x323036268911990100/1-01.png",
        name: "A",
        point:0,
        value:"0"
    },
    {
        id:2,
        imageUrl: "https://s3.amazonaws.com/appforest_uf/f1598816546285x597499527021516900/1-02.png",
        name: "B",
        point:0,
        value:"0"
    },
    {
        id:3,
        imageUrl: "https://s3.amazonaws.com/appforest_uf/f1598816563257x856809071469836700/1-05.png",
        name: "C",
        point:2,
        value:"1"
    },
    {
        id:4,
        imageUrl: "https://s3.amazonaws.com/appforest_uf/f1598816584881x936742328838640800/1-08.png",
        name: "D",
        point:0,
        value:"0"
    }
]

export const clockTimesOptions = [
    {
        id:1,
        imageUrl: "https://s3.amazonaws.com/appforest_uf/f1608136531646x856252128082152100/correct.png",
        name: "A",
        point:2,
        value:"1"
    },
    {
        id:2,
        imageUrl: "https://s3.amazonaws.com/appforest_uf/f1608136263516x589821305936679700/0.png",
        name: "B",
        point:0,
        value:"0"
    },
    {
        id:3,
        imageUrl: "https://s3.amazonaws.com/appforest_uf/f1608136340500x604307397546535200/950.png",
        name: "C",
        point:0,
        value:"0"
    },
    {
        id:4,
        imageUrl: "https://s3.amazonaws.com/appforest_uf/f1608136474917x631009250327383440/11.png",
        name: "D",
        point:0,
        value:"0"
    }
]

export const inputTextOptions = [
    {
        id:1,
        title: "Object #1",
        name:"object1"
    },
    {
        id:2,
        title: "Object #2",
        name:"object2"
    },
    {
        id:3,
        title: "Object #3",
        name:"object3"
    },
    {
        id:4,
        title: "Object #4",
        name:"object4"
    },
    {
        id:5,
        title: "Object #5",
        name:"object5"
    }
]