import React from 'react';
import ResultCard from '../ResultCard';
import ResultContainer from '../ResultContainer';

const ResultsList = ({heading, examList, isAdmin, examId}) => {


    return (
        <ResultContainer heading={heading}>
             {
                    examList.map((exam) => (
                        <ResultCard
                            title={exam.title}
                            maxScore={exam.maxScore}
                            preferredScoreLevel={exam.preferredScoreLevel}
                            isAdmin={isAdmin}
                            examId={examId}
                        />
                    ))
                }
        </ResultContainer>
    )
};

export default ResultsList;
