import {Row } from 'antd'
import React from 'react'
import styles from './index.module.css'

const ResultContainer = ({heading, children, className}) => {

    return (
        <div className={styles._}>
            <h3>{heading}</h3>
            <Row className={`${styles.container} ${className}`} gutter={[20, 20]}  wrap={true}>
               {children}
            </Row>
        </div>
    )
}

export default ResultContainer
