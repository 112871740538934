import { Button, Input, message } from 'antd'
import React, { useState } from 'react'
import { useEffect } from 'react'
import useAxios from '../../../hooks/useAxios'
import { CHANGE_PASSWORD } from '../../../utils/endpoints'
import styles from './index.module.css'
import useNotification from '../../../hooks/useNotification'
import { generateRandomPassword } from '../../../utils/helper'

const ResetPassword = ({setIsModalVisible, contactData}) => {

    const [email, setEmail] = useState(null)

    const { data: changePasswordData, error: changePasswordError, isLoading: changingPassword, dispatchFetch: changePassword,} = useAxios({ method: "POST", url: CHANGE_PASSWORD});

    const {submittingNotificationData, notificationData, notificationError, dispatchNotification} = useNotification()

    // Generate new password for user
    const onFinish = () => {
        changePassword({
            id: contactData?.id,
            data: {
                Password: generateRandomPassword(),
                firstTimeLogin : 1
            }
        })
    };


    // Send email to user
    useEffect(() => {
        if(changePasswordData?.data?.status === "success"){
            dispatchNotification({
                full_name: contactData?.Full_Name,
                website_url:`${process.env.REACT_APP_WEBSITE_URL}/admin/login`,
                password:changePasswordData?.data?.data['New Password']
            }, email, process.env.REACT_APP_RESET_SENDGRID_TEMPLATE_ID)
        }
    }, [changePasswordData])

    const handleChange = (e) => {
        const value = e.target.value
        setEmail(value)
    }

    useEffect(() => {
        if(notificationData || notificationError){
            setEmail(null)
            message.success('Password reset email sent successfully')
            setIsModalVisible(false)
        }
    }, [notificationData, notificationError])

    useEffect(() => {
        changePasswordError && message.error(changePasswordError)
    }, [changePasswordError])


    return (
        <>
            <div className={styles.content}>
                <p>Enter the email address associated with this account, and we'll email a link to reset the password.</p> 
            </div>
            <div className={styles.reset_input_form}>
                <Input className="input-secondary" name="passwordReset" onChange={handleChange} value={email} placeholder="Email" />
                <Button className='--card-button-active' style={{marginTop:"20px"}} loading={changingPassword || submittingNotificationData} onClick={onFinish}>SEND RESET LINK</Button>
            </div>
        </>
  )
}

export default ResetPassword