import { Col, Row, Form } from 'antd'
import React from 'react'
import bicycle_riders from '../../assets/images/bicycle-riders.png'
import styles from './index.module.css'

const EntryContainer = ({title, onFinish, onFinishFailed, children}) => {

    return (
        <Row style={{padding: '70px 0'}} gutter={[{ xs: 8, sm: 16, md: 24, lg: 110 }, 40]}>
            <Col xs={24} md={12} lg={14} xl={14} sm={{ order: 1 }}>
                <div className={styles.welcome_section}>
                    <div className={styles.text_content}>
                        <h1>Welcome to the Annual Wellness Visit</h1>
                        <p>Using this assessment, you may identify possible treatable geriatric problems. Our surveys have been developed in a partnership with Saint Louis University and will help you identify issues before speaking with your physician.</p>
                    </div>
                    <div className={styles.image_content}>
                        <img src={bicycle_riders} alt="old people on a bicycle" />
                    </div>
                </div>
            </Col>
            <Col xs={24} md={12} lg={10} xl={10} sm={{ order: 2 }} >
                <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout='vertical'
                    className={styles.form_container}
                >
                    <div className={styles.header}>
                        <h2>{title}</h2>
                    </div>
                    <div className={styles.children}>
                        { children }
                    </div>
                </Form>
            </Col>
        </Row>
        
    )
}

export default EntryContainer
