import React, { useEffect }  from 'react'
import { useNavigate } from 'react-router-dom'
import OrganizationStatistics from '../../components/Admin/OrganizationStatistics'
import useAuth from '../../hooks/useAuth'

const Organization = () => {

  let navigate = useNavigate()
  const { isAdmin, isProfessional, dispatchContactData } = useAuth()

  // load contact details when logged in

  useEffect(() => {
      dispatchContactData()
  }, [])

  useEffect(() => {
    if(!isAdmin && isProfessional){
      navigate("/admin/professional")
    }
    if(!isAdmin && !isProfessional){
      navigate("/")
    }
  },[])

  return (
    <>
        <OrganizationStatistics />
    </>
  )
}

export default Organization