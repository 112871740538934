import { Input } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import NextButton from '../NextButton';
import styles from './index.module.css'

const SingleInputOption = ({setAnswerResponse, setPoints, nextQuestion, name}) => {

    const [stateInput, setStateInput] = useState({})
    const [stateScore, setStateScore] = useState(0)

    const rcsQuizAns = "chicago"

    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value.toLowerCase().trim() === rcsQuizAns ? 1 : 0
        setStateInput({
            [name]:value
        })
    } 

    const handleSubmit = () => {
        setAnswerResponse((prevAnswerResponse) => ({
            ...prevAnswerResponse,
            ...stateInput
        }))

        if(stateInput?.Correct_State === 1){
            setPoints((prevPoint) => ([
                ...prevPoint,
                stateScore + 1
            ]))
        }
        nextQuestion()
    }

    return( 
        <div className={styles.single_input}>
            <div style={{marginBottom:'30px', marginRight:'40px'}}>
                <Input className="input" name={name} onChange={handleChange} placeholder="Type here..." style={{width:'350px', maxWidth:'100%'}} />
            </div>
            <NextButton  handleSubmit={handleSubmit} />
        </div>
    )
};

export default SingleInputOption;
