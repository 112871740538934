import React, { useEffect }  from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import OrganizationProfessionals from '../../components/Admin/OrganizationProfessionals'

const ManageProfessionals = () => {

  let navigate = useNavigate()
  const { isAdmin, isProfessional, dispatchContactData } = useAuth()

  // load contact details when logged in

  useEffect(() => {
      dispatchContactData()
  }, [])

  useEffect(() => {
    if(!isAdmin && isProfessional){
      navigate("/admin/professional")
    }
    if(!isAdmin && !isProfessional){
      navigate("/")
    }
  },[])

  return (
    <>
        <OrganizationProfessionals />
    </>
  )
}

export default ManageProfessionals