import {Card, Col, Skeleton } from 'antd'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import useAxios from '../../hooks/useAxios'
import LoadingState from '../LoadingState'
import styles from './index.module.css'

const ResultCard = ({title, preferredScoreLevel, isAdmin, examId, maxScore}) => {

    const [includeSuggestion, setIncludeSuggestion] = useState(false)

    const {exam_info} = useSelector(state => state.exam)

    const { data: testData, error: testError, isLoading: loadingTest, dispatchFetch: fetchTest } = useAxios({ method: "GET", url: `/index.php/${title}?Exam=${!isAdmin ? exam_info?.id : examId}&asList=1`});

    const testDetails = testData?.data.data[testData?.data?.data.length - 1]
    const score = testDetails?.Score || '--'

    const checkExamSuggestion = () => {
        if((title === 'amsad' || title === 'sarcf') && score >= 4){
            setIncludeSuggestion(true)
        }
        if((title === 'lifead' || title === 'alone' || title === 'energy' || title === 'adam' || title === 'rcs') && score <=7){
            setIncludeSuggestion(true)
        }
        if(title === 'snaq' && score <= 14){
            setIncludeSuggestion(true)
        }
        if((title === 'adls' || title === 'iadls') && score <= 5){
            setIncludeSuggestion(true)
        }
        if(title === 'frail-nh' && score >= 6){
            setIncludeSuggestion(true)
        }
        if(title === 'eat-survey' && score <=8){
            setIncludeSuggestion(true)
        }
        if((title === 'hearing-survey' && (score >= 7 || testDetails?.Poor_Balance === "1" || testDetails?.Head_Trauma === "1"))){
            setIncludeSuggestion(true)
        }
        if((title === 'frail') && (testDetails?.Kick_spouse_think_dead === "1" || testDetails?.Fall_asleep_multiple_times_a_day === "1" || testDetails?.Is_systolic_blood_pressure_over_12 === "1" ||testDetails?.Cannot_walk_one_flight_steps === "1" || testDetails?.Cannot_walk_one_block === "1")){
            setIncludeSuggestion(true)
        }
        if((title === 'weight-survey') && (testDetails?.Problems_Remembering === "1" || testDetails?.Stools_Watery_Semiformed === "1" || testDetails?.Ab_Pain === "1")){
            setIncludeSuggestion(true)
        }
        if((title === 'f3alls') && (testDetails?.Fallen_in_the_last_6_months === "1" || testDetails?.Afraid_of_Falling === "1" || testDetails?.Foot_Pain === "1" || testDetails?.Unsteady === "1" || testDetails?.Lost_Strength === "1" || testDetails?.Systolic_BP_less_than_120 === "1" || testDetails?.Pass_Out === "1")){
            setIncludeSuggestion(true)
        }
    }


    const updateTitle = () => {
        if(title.includes('-survey')){
            return title.replace('-survey', '')
        }
        return title
    }

    useEffect(() => {
        fetchTest()
    }, [isAdmin, examId])

    useEffect(() => {
        checkExamSuggestion()
    })

    return (
        <Col>
            {
                loadingTest ? 
                <Skeleton.Button active style={{height:100, width:150}}/> :
                (!testDetails?.Score && preferredScoreLevel !== "none") ? null :
                <div style={{padding:"0"}} className={styles.result_card}>
                    <div className={styles.title}>
                        <h4>{updateTitle()}</h4>
                    </div>
                    <div className={styles.score}>
                        {
                            preferredScoreLevel === "none" ? <></> : <p>{score}/{maxScore}</p>
                        }
                    </div>
                    <div className={`${styles.no_suggestion} ${includeSuggestion && styles.suggestion }`}>
                        { 
                            includeSuggestion && <Link 
                            to={`/results/suggestions/${title}${isAdmin ? `?user=admin&exam=${examId}` : ''}`}
                            >Suggestion</Link>
                        }
                    </div>
                </div>
            }
        </Col>
    )
}

export default ResultCard
