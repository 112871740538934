import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import ResultContainer from '../ResultContainer';
import styles from './index.module.css'
import useAuth from '../../hooks/useAuth';

const PatientConcerns = ({heading}) => {

    let params = useParams()
    const { isAdmin, isProfessional } = useAuth()

	const { data: whatMattersData, dispatchFetch: fetchWhatMatters } = useAxios({ method: "GET", url: `/index.php/what-matters?Exam=${params.exam_id}&asList=1`});

    const whatMattersTest = whatMattersData?.data.data[whatMattersData?.data?.data.length - 1]

	useEffect(() => {
		fetchWhatMatters()
	}, [])

    return(
        <ResultContainer heading={heading} className={styles.concerns_container} >
            <div className={styles.concerns}>
                <p>{(whatMattersTest?.Important_to_know === null || whatMattersTest?.Important_to_know === "") ? `${(isProfessional || isAdmin) ? 'The patient' : 'You'} did not note any important issues when taking the exam.` : whatMattersTest?.Important_to_know}</p>
            </div>
        </ResultContainer>
    )
};

export default PatientConcerns;
