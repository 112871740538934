export const BASE_URL = process.env.REACT_APP_BASE_URL
export const LOGIN = "/login.php"
export const PATIENT_INFO = "/index.php/patient-info"
export const PATIENT_LOGIN = "/patientlogin.php"
export const CONTACT = "/index.php/contact"
export const EXAM = "/index.php/exam"
export const NOTIFICATION = "/index.php/notification"
export const ADD_OAUTH ="/index.php/system/add-oauth"
export const PROCLE_GUEST_USER = "/procle.php/guest-user"
export const PROCLE_USER = "/procle.php/user"
export const LOGIN_HISTORY = "/index.php/login-history"
export const CHANGE_PASSWORD = "/index.php/system/change-password"
export const RESET_PASSWORD = "/index.php/system/reset-password"
export const REFRESH_TOKEN = "/token.php"
export const RESOURCE = "/resource.php"
export const GET_ORGS = '/getorgs.php'
