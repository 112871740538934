import { Button } from 'antd'
import React from 'react'
import QuestionWrapper from '../QuestionWrapper'
import styles from './index.module.css'

const ExamStart = ({title, description, setStartExam}) => {

    const handleStartExam = () => {
        setStartExam(true)
    }

    return (
        <div className={styles._}>
            <QuestionWrapper 
                question={`Welcome to the ${title.toUpperCase()} Test`}
                recommendation={description}
            >
                <div style={{ paddingTop: '40px' }}>
                    <Button 
                        type="primary" className="button --primary"
                        onClick={() => handleStartExam()}>
                        Start
                    </Button>
                </div> 
            </QuestionWrapper>
        </div>
    )
}

export default ExamStart
