import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useAxios from '../../hooks/useAxios';
import ResultContainer from '../ResultContainer';
import SummaryCard from '../SummaryCard';
import { Col, Row, Skeleton } from 'antd';

const HistoryList = ({heading}) => {

	let params = useParams()


	const {exam_info} = useSelector(state => state.exam)

	const { data: adlsData, isLoading: loadingAdls, dispatchFetch: fetchAdls } = useAxios({ method: "GET", url: `/index.php/adls?Exam=${params.exam_id}&asList=1`});

    const adlsTest = adlsData?.data.data[adlsData?.data?.data.length - 1]


	useEffect(() => {
		fetchAdls()
	}, [])

	return (
		<ResultContainer heading={heading}>
			{
				loadingAdls ? 
				<Row gutter={24}> 
					{
						 Array(5).fill(null).map(() => (
							<Col>
								<Skeleton.Button active style={{height:100, width:150, margin:10}}/>
							</Col>
						))  
					}
				</Row>
				:
				<>
					<SummaryCard
						title="Pain Score"
						content={adlsTest?.Pain_Score}
					/>
					<SummaryCard
						title="Drinks Per Week"
						content={adlsTest?.Num_Drinks}
					/>
					<SummaryCard
						title="Problem Seeing?"
						content={adlsTest?.Seeing === "0" ? "no" : "yes"}
					/>
					<SummaryCard
						title="Problem Hearing?"
						content={adlsTest?.Hearing=== "0" ? "no" : "yes"}
					/>
					<SummaryCard
						title="Smoke?"
						content={adlsTest?.Smoke === "0" ? "no" : "yes"}
					/>
					<SummaryCard
						title="Days of Exercise"
						content={adlsTest?.Exercise.replace("Days", '')}
					/>
					<SummaryCard
						title="Sexually Active"
						content={adlsTest?.Sexually_Active === "0" ? "no" : "yes"}
					/>
					<SummaryCard
						title="Happy with Sex"
						content={adlsTest?.Happy_Sex.toLowerCase()}
					/>
				</>
			}
			
		</ResultContainer>
	)

};

export default HistoryList;
