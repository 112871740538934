import React from 'react'
import ResultInfo from '../components/ResultInfo'
import ResultsHeader from '../components/ResultsHeader'
import ResultsList from '../components/ResultsList'
import { EXAM_LIST } from '../utils/examdata'
import { useLocation, useNavigate } from 'react-router-dom'
import { getFullName } from '../utils/helper'
import { useSelector } from 'react-redux'
import { Button, Result } from 'antd'
import { paths } from '../routes/paths'
import useAuth from '../hooks/useAuth'


const Results = () => {
    
    const location = useLocation()
    const navigate = useNavigate()
    const { isAuth } = useAuth()
    const {exam_info} = useSelector(state => state.exam)
    const searchParams = new URLSearchParams(location.search)
    const isAdmin = searchParams.get('user') === "admin"
    const examId = searchParams.get('exam')
    const patientInfo = location.state

    const highScoreLevelExam = EXAM_LIST.filter((exam) => exam.preferredScoreLevel === "high")
    const lowScoreLevelExam = EXAM_LIST.filter((exam) => exam.preferredScoreLevel === "low")
    const noScoreLevelExam = EXAM_LIST.filter((exam) => exam.preferredScoreLevel === "none")

    const adminHeaderText = `Exam Results for ${getFullName(patientInfo?.First_Name, patientInfo?.Last_Name)}`
    
    if(exam_info?.Completed !== "true" && (isAuth && !isAdmin)){
        return(
            <div className="result_container">
                <Result
                    status="warning"
                    title="Exam not completed"
                    subTitle="Please complete the exam to view results"
                    extra={[
                        <Button type="primary" style={{width:"200px"}} className='button --card-button --card-button-active' onClick={() => navigate(paths.DASHBOARD)}>
                        Go Home
                        </Button>,
                    ]}
                />
            </div>
        )
    }

    return (
        <>
            <ResultsHeader 
                adminHeaderText={adminHeaderText}
            />
            <ResultsList 
                heading="High results may need further investigation" 
                examList={lowScoreLevelExam} 
                isAdmin={isAdmin}
                examId={examId}
            />
            <ResultsList 
                heading="Low results may need further investigation" 
                examList={highScoreLevelExam}
                isAdmin={isAdmin}
                examId={examId}
            />
            <ResultsList 
                heading="These results may include some question specific recommendations" 
                examList={noScoreLevelExam}
                isAdmin={isAdmin}
                examId={examId}
            />
            <ResultInfo 
                isAdmin={isAdmin}
                examId={examId}
                patientInfo={patientInfo}
            />
        </>
    )
}

export default Results
