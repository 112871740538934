import * as types from '../actions/types';

const initialState = {
  exam_info:localStorage.getItem('awv_examInfo') !== "undefined" && (JSON.parse(localStorage.getItem('awv_examInfo')) || {})
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case types.LOAD_EXAM:
        localStorage.setItem('awv_examInfo', JSON.stringify(action.payload));
        return {
            ...state,
            exam_info: action.payload || state.exam_info,
        }
    case types.LOGOUT: 
        localStorage.removeItem('awv_examInfo');
        return {
           ...state,
           exam_info: null, 
        }
    default:
      return state;
  }
}

export default reducer;