import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers/rootReducer';

const middlewares = [reduxThunk];

const store = createStore(
    rootReducer, 
    composeWithDevTools(applyMiddleware(...middlewares),
));

export default store;