import { Button, Card, Col } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useAxios from '../../hooks/useAxios'
import styles from './index.module.css'
import { paths } from '../../routes/paths'

const DashboardCard = ({title, image, description, link, progress, loading}) => {

    let navigate = useNavigate()
    const {exam_info} = useSelector(state => state.exam)

    const { data: testData, error: testError, isLoading: loadingTest, dispatchFetch: fetchTest } = useAxios({ method: "GET", url: `/index.php/${title}?Exam=${exam_info?.id}&asList=1`});

    useEffect(() => {
        fetchTest()
    }, [testData?.data?.data.length])

    return (
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
            <Card  className={`${styles.card} exam-card`} hoverable={true}>
                <div className={styles.title}>
                    <h1>{title}</h1>
                </div>
                <div className={styles.image}>
                    <img src={image} alt={title} />
                </div>
                <div className={styles.description}>
                    <p>{description}</p>
                </div>
                {
                    (title === 'snaq' && (progress === 'weight-survey' || progress === 'eat-survey')) || 
                    (title === 'sarcf' && (progress === 'f3alls')) || 
                    (title === 'rcs' && (progress === 'hearing-survey')) ||
                    (title === 'adls' && (progress === 'iadls')) ? 
                        <Button type="primary" 
                            className={`button ${styles.card_button_active}`} 
                            onClick={() => navigate(`${paths.DASHBOARD}/${progress}`)} 
                            disabled={false}
                        >
                            Resume
                        </Button> :

                        <Button type="primary" 
                            className={`button ${ progress === title ? styles.card_button_active : styles.card_button}`} 
                            onClick={() => navigate(`${paths.DASHBOARD}${link}`)} 
                            disabled={(progress === title) ? false : true}
                        >
                            {(testData && testData?.data?.data.length !== 0) ? 'Completed' : 'Start' }
                        </Button>
                }
            </Card>
        </Col>
    )
}
export default DashboardCard
