import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ExamReviews from '../../components/Admin/ExamReviews'
import PatientAppointments from '../../components/Admin/PatientAppointments'
import PatientList from '../../components/Admin/PatientList'
import useAuth from '../../hooks/useAuth'
import { Tabs } from 'antd'
import { useSelector } from 'react-redux'

const Professional = () => {

  let navigate = useNavigate()
  const { isAdmin, isProfessional, dispatchContactData } = useAuth()
  const auth = useSelector(state => state.auth)

  useEffect(() => {
      dispatchContactData()
  }, [])

  useEffect(() => {
    if(!isProfessional && isAdmin){
      navigate("/admin/organization")
    }
    if(!isAdmin && !isProfessional){
      navigate("/")
    }
  },[])



  return (
    <div className='professionals-page'>
        {/* <PatientAppointments /> */}
        <Tabs>
          <Tabs.TabPane tab="Manage Patients" key="patients">
            <PatientList />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Manage Exams" key="exams">
            <ExamReviews />
          </Tabs.TabPane>
        </Tabs>
    </div>
  )
}

export default Professional