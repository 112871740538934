import React, { useEffect } from 'react';
import ResultsHeader from '../components/ResultsHeader';
import ScoresPanel from '../components/ScoresPanel';
import VaccinationList from '../components/VaccinationList';
import { history, vaccinations } from '../utils/summarydata';
import HistoryList from '../components/HistoryList';
import PatientConcerns from '../components/PatientConcerns';
import { useLocation } from 'react-router-dom';
import { getFullName } from '../utils/helper';

const Summary = () => {
    const location = useLocation()
    const patientInfo = location.state

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const adminHeaderText = `Results Summary for ${getFullName(patientInfo?.First_Name, patientInfo?.Last_Name)}`

    return (
        <div>
            <ResultsHeader  
                adminHeaderText={adminHeaderText}
            />
            <ScoresPanel content="Patient Information"/>
            {/* <VaccinationList heading="vaccinations" vaccinationItems={vaccinations}/> */}
            <HistoryList heading="history" />
            <PatientConcerns heading="Patient Concerns"/>
        </div>
    )
};

export default Summary;
