import { Space } from 'antd';
import React from 'react';
import styles from './index.module.css'

const QuestionWrapper = ({question, recommendation, sub_label, examType, children}) => {
    return (
        <div className={styles._}>
            <div className={styles.header}>
               <div className={styles.question_text}>
                   {
                       examType === "amsad" &&
                       <p>Within the last 2 weeks, how many times have you experienced:</p>
                   }
                    <p>{question}</p>
               </div>
                {
                  recommendation &&  
                    <div className={styles.sub_question_text}>
                            <p>{recommendation}</p>
                    </div>
                }
                {
                    sub_label && 
                    <div className={styles.sub_question_text}>
                        <Space size={[80, 20]} wrap>
                            {
                                sub_label.map((item) => 
                                    <p key={item} className={sub_label.length > 1 ? `${styles.sub_label_list}`: ''}>{item}</p>
                                )
                            }
                        </Space>
                    </div>
                }
              
            </div>
           { 
            children &&
                <div className={styles.content}>
                    {children}
                </div>
            }
        </div>
    )
}

export default QuestionWrapper