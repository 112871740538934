import { Button, Col, Row, Space } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useAxios from '../../hooks/useAxios'
import { EXAM_LIST } from '../../utils/examdata'
import LoadingState from '../LoadingState'
import ScoresPanel from '../ScoresPanel'
import styles from './index.module.css'

const SuggestionDetails = () => {
    let params = useParams()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const isAdmin = searchParams.get('user') === "admin"
    const examId = searchParams.get('exam')

    const {exam_info} = useSelector(state => state.exam)

    const [examRecommendation, setExamRecommendation] = useState(false)
    const questionRecommendation = []

    const { data: testData, isLoading: loadingTest, dispatchFetch: fetchTest } = useAxios({ method: "GET", url: `/index.php/${params.exam}?Exam=${!isAdmin ? exam_info?.id : examId}&asList=1`});

    const testDetails = testData?.data.data[testData?.data?.data.length - 1]
    const score = testDetails?.Score || '--'

    const currentExam = EXAM_LIST.find( ({title}) => title === params.exam)

    const checkExamRecommendation = () => {
        if((params.exam === 'amsad' || params.exam === 'sarcf') && score >= 4){
            setExamRecommendation(true)
        }
        if((params.exam === 'lifead' || params.exam === 'alone' || params.exam === 'energy' || params.exam === 'adam' || params.exam === 'rcs') && score <=7){
            setExamRecommendation(true)
        }
        if(params.exam === 'snaq' && score <= 14){
            setExamRecommendation(true)
        }
        if((params.exam === 'adls' || params.exam === 'iadls') && score <= 5){
            setExamRecommendation(true)
        }
        if(params.exam === 'frail-nh' && score >= 6){
            setExamRecommendation(true)
        }
        if((params.exam === 'hearing-survey' && score >= 7) || (params.exam === 'eat-survey' && score <=8)){
            setExamRecommendation(true)
        }
    }

    const includeQuestionRecommendation = () => {
        // #7
        if(testDetails?.Kick_spouse_think_dead === "1"){
            questionRecommendation.push("Consider Measuring B12. TSH and CBC levels")
        }
        // #8
        if(testDetails?.Fall_asleep_multiple_times_a_day === "1"){
            questionRecommendation.push("Consider scheduling a sleep schedule")
        }
        // #9
        if(testDetails?.Is_systolic_blood_pressure_over_12 === "1"){
            questionRecommendation.push("Have your pressure checked lying and standing a number of times")
        }
         // #10 - #12
         if(testDetails?.Cannot_walk_one_flight_steps === "1" || testDetails?.Cannot_walk_one_block === "1"){
            questionRecommendation.push("Consider becoming involved in an exercise program")
            questionRecommendation.push("Consider leucine enriched essential amino acid supplement")
            questionRecommendation.push("Consider ACTH slim test for Addisons")
        }
        // #13
        if(testDetails?.Problems_Remembering === "1"){
            questionRecommendation.push("Measure HBGA1C, TSH, K anad Eosinophils")
        }
        // #14
        if(testDetails?.Stools_Watery_Semiformed === "1"){
            questionRecommendation.push("Measure tissue transglutaminase lgA")
        }
        // #15
        if(testDetails?.Ab_Pain === "1"){
            questionRecommendation.push("Consider Abdominal ultrasound")
        }
        // #16
        if(testDetails?.Poor_Balance === "1"){
            questionRecommendation.push("Ask your physician about Normal Pressure Hydrocephalus")
        }
        // #17
        if(testDetails?.Head_Trauma === "1"){
            questionRecommendation.push("Your physicial should consider that you may have post-traumatic injury syndrome")
        }
        // #18
        if(testDetails?.Fallen_in_the_last_6_months === "1"){
            questionRecommendation.push("Consider having a falls assessment. Consider physical therapy")
        }
        // #19
        if(testDetails?.Afraid_of_Falling === "1"){
            questionRecommendation.push("Consider exercise and balance program")
        }
        // #20
        if(testDetails?.Foot_Pain === "1"){
            questionRecommendation.push("If diabetic, assess for peripheral neuropathy")
        }
        // #21
        if(testDetails?.Unsteady === "1"){
            questionRecommendation.push("Consider balance exercises and medication review")
        }
        // #22
        if(testDetails?.Lost_Strength === "1"){
            questionRecommendation.push("Consider resistance exercise program")
        }
        // #23
        if(testDetails?.Systolic_BP_less_than_120 === "1"){
            questionRecommendation.push("Ask the physician if your drugs could be causing the falls. Your physician should evaluate for autonomy neuropathy, Consider performing CBC")
        }
        // #24
        if(testDetails?.Pass_Out === "1"){
            questionRecommendation.push("Consider providing an event monitor/loop recorder")
        }
        // #25
        if(testDetails?.Completed_Advance_Directive === "0"){
            questionRecommendation.push("Make an appointment with your health care provider to discuss developing an advance directvie")
        }
    }

    includeQuestionRecommendation()

    useEffect(() => {
        checkExamRecommendation()
    })

    useEffect(() => {
        fetchTest()
    }, [])

    if(loadingTest){
        return(
            <LoadingState text="Loading Suggestions..."/>
        )   
    }

    let content =  <p>{currentExam?.title} 
        {
            (currentExam?.title !== 'weight-survey' && currentExam?.title !== 'f3alls') && 
            <span> {score}/{currentExam?.maxScore}</span>
        }
    </p>

    return (
        <div className={styles._}>
            <ScoresPanel content={content}/>
            <div className={styles.suggestion_list}>
               { 
                examRecommendation &&
                <ul>
                        <Space size={10} direction='vertical'> 
                            <li>{currentExam?.recommendation || '---'}</li>
                        </Space>
                    </ul>
                }
                
                {
                   questionRecommendation &&  
                    <ul>
                    <Space size={10} direction='vertical'>
                        {
                            questionRecommendation.map((recommendation) => {
                                return  <li>{recommendation}</li>
                            })
                        }
                    </Space>
                </ul>}
            </div>
        </div>
    )
}

export default SuggestionDetails
