import { Space } from 'antd'
import React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useAxios from '../../../hooks/useAxios'
import { EXAM, PATIENT_INFO } from '../../../utils/endpoints'
import styles from './index.module.css'
import moment from 'moment'
import LoadingState from '../../LoadingState'

const OrderSheetContainer = () => {

  const { patient_id, exam_id } = useParams()


  const { data: patientData, isLoading: loadingPatientData, dispatchFetch: fetchPatientData,} = useAxios({ method: "GET", url: PATIENT_INFO, params: { id: patient_id, asList: 1 } });

  const { data: examData, isLoading, dispatchFetch: fetchExamData } = useAxios({ method: "GET", url: EXAM, params: { id: exam_id, asList: 1 }});

  const patientDetails = patientData?.data.data[0]
  const examDetails = examData?.data.data[0]

  useEffect(() => {
    fetchPatientData()
    fetchExamData()
  }, [])

  return (
    <div className={styles.order_sheet_container}>
      <div className={styles.heading}>
        <h1>Annual Wellness Visit Order Sheet</h1>
      </div>
      {
        loadingPatientData ? <LoadingState text="loading patient details..."/> :
        <div className={styles.patient_details}>
          <div className={styles.details_item}>
            <p>Patient Name:</p>
            <p>{patientDetails?.First_Name} {patientDetails?.Last_Name}</p>
          </div>
          <div className={styles.details_item}>
            <p>Patient DOB:</p>
            <p>{moment(`${patientDetails?.Date_of_Birth}`).format('MMM Do YYYY')}</p>
          </div>
          <div className={styles.details_item}>
            <p>Patient ID:</p>
            <p>{patientDetails?.id}</p>
          </div>
          <div className={styles.details_item}>
            <p>Date:</p>
            <p>{moment(`${examDetails?.Scheduled}`).format('MMM Do YYYY, h:mm:ss a')}</p>
          </div>
          <div className={styles.details_item}>
            <p>CPT Billing Code:</p>
            <div className={styles.billing_items}>
                <div className={styles.billing_item}>
                  <p>New Patient</p>
                  <p>324900-394203-234</p> 
                </div>
                <div className={styles.billing_item}>
                  <p>Established Patient</p>
                  <p>324900-394203-234</p> 
                </div>
            </div>
          </div>
        </div>
      }
      <div className={styles.recommendation_section}>
          <h2>Referral Recommendations</h2>
      </div>
    </div>
  )
}

export default OrderSheetContainer