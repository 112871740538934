import { Layout } from 'antd'
import React from 'react'
import styles from './index.module.css'

const Container = ({children, className}) => {
    return (
        <Layout className={`${styles.container} ${className}`}>
            {children}
        </Layout>
    )
}

export default Container
