import { Button, Form, Input } from 'antd';
import React from 'react';
import styles from './index.module.css'

const TextAreaInput = ({nextQuestion, name, setAnswerResponse}) => {

    const onFinish = (values) => {
        setAnswerResponse((prevAnswerResponse) => ({
            ...prevAnswerResponse,
            ...values
        }))
        nextQuestion()
    }

    const { TextArea } = Input;

    return(
        <div className={styles._main}>
                <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    autoComplete="off"
                    layout='vertical'
                    className={styles.form_container}
                >
                    <Form.Item
                        label=""
                        name={name}
                        required={false}
                        className={styles.text_area}
                    >
                        <TextArea rows={8} className={styles.text_area_input} style={{width:'50vw'}}/>
                    </Form.Item>
                    <Form.Item className={styles.next_btn}>
                        <Button type="primary" className="button --primary"
                            style={{width:"inherit", minWidth:'200px'}}
                            htmlType="submit"
                        >
                            Next
                        </Button>
                    </Form.Item>
                </Form>
        </div>
    )
};

export default TextAreaInput;
