import { Input, Space } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { inputTextOptions } from '../../utils/optionsdata';
import NextButton from '../NextButton';
import styles from './index.module.css'

const MultipleInputOptions = ({setAnswerResponse, setPoints, nextQuestion, name}) => {

    const [wordInput, setWordInput] = useState({})
    const [wordScore, setWordScore] = useState(0)
    const [submit, setSubmit] = useState(false)

    const handleChange = (e) => {
        const name = e.target.name
        setWordInput((prevWordInput) => ({
            ...prevWordInput,
            [name]:e.target.value
        }))
    }

    const totalCorrectWords = () => {
        if(wordInput.object1 && wordInput.object1.toLowerCase() === 'apple'){
            setWordScore((prevScore) => 
                prevScore + 1
            )
        }
        if(wordInput.object2 && wordInput.object2.toLowerCase() === 'pen'){
            setWordScore((prevScore) => 
                prevScore + 1
            )
        }
        if(wordInput.object3 && wordInput.object3.toLowerCase() === 'tie'){
            setWordScore((prevScore) => 
                prevScore + 1
            )
        }
        if(wordInput.object4 && wordInput.object4.toLowerCase() === 'house'){
            setWordScore((prevScore) => 
                prevScore + 1
            )
        }
        if(wordInput.object5 && wordInput.object5.toLowerCase() === 'car'){
            setWordScore((prevScore) => 
                prevScore + 1
            )
        }
    }

    const handleSubmit = () => {
        totalCorrectWords()
        setSubmit(true)
        nextQuestion()
    }

    
    useEffect(() => {
        if(submit){
            setAnswerResponse((prevAnswerResponse) => ({
                ...prevAnswerResponse,
                [name]:wordScore
            }))
    
            setPoints((prevPoint) => ([
                ...prevPoint,
                wordScore
            ]))
        }

    }, [submit])

    return(
        <div className={styles.multiple_input}>
            <Space size={[30, 20]} wrap>
                {
                    inputTextOptions.map((option) => {
                        return (
                        <div key={option.id} className={styles.input_options}>
                            <p>{option.title}</p>
                            <Input className="input" name={option.name} onChange={handleChange} style={{width:'180px'}} placeholder="Type here..."/>
                        </div>
                        )
                    })
                }
            </Space>
            <NextButton  handleSubmit={handleSubmit} disabled={wordInput.object1 || wordInput.object2 || wordInput.object3 || wordInput.object4 || wordInput.object5 ? false : true}/>
        </div>
    )
};

export default MultipleInputOptions;
