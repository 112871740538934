import { Col, Row, Form, Input, Button, DatePicker, Select, message  } from 'antd'
import React, { useEffect } from 'react'
import Wrapper from '../Wrapper'
import old_people from '../../../assets/images/old_people.png'
import styles from './index.module.css'
import useAxios from '../../../hooks/useAxios'
import { PATIENT_INFO, PROCLE_USER } from '../../../utils/endpoints'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment';
import useAuth from '../../../hooks/useAuth'
import { paths } from '../../../routes/paths'

const PatientInfo = () => {

    let navigate = useNavigate()

    const { patientID, patientInfo } = useSelector(state => state.auth); 
    const {isRegistered, isAdmin, isProfessional, singlePatientData, dispatchSinglePatientData} = useAuth()
    
    const { data: registrationData, error: registrationError, isLoading: registering, dispatchFetch: dispatchRegister } = useAxios({ method: "POST", url: PATIENT_INFO});

    const { data: procleUserData, error: procleUserError, isLoading: creatingProcleUser, dispatchFetch: createProcleUser, } = useAxios({ method: "POST", url: PROCLE_USER});

    const {data: procleUpdatedUserData, error: procleUpdatedUserError, isLoading: updatingProcleUser, dispatchFetch: updateProcleUser} = useAxios({  method: "POST", url: PROCLE_USER, params: { cc: +1, phone:patientInfo?.Phone} });

    const onFinish = (values) => {
        // console.log('Success:', values);
        const phoneNumber = `${values.prefix}${values.Phone}`
        const body = [
            {
                "id": patientID,
                "data": {
                    ...delete values.prefix,
                    ...values
                    // Phone: phoneNumber,
                }
            }
        ]
        dispatchRegister(body)

        const updateProcleBody = {
            orgId: process.env.REACT_APP_PROCLE_ORG_ID,
            hospitalId: process.env.REACT_APP_PROCLE_HOSPITAL_ID,
            prefix:values.Sex.toLowerCase() === "male" ? "Mr" : "Mrs",
            firstName:values.First_Name,
            lastName:values.Last_Name,
            mobileNumber:values.Phone,
            dateOfBirth:values.Date_of_Birth.format('YYYY-MM-DD'),
            userTimezone:"Central Standard Time (CST)",
            userRoleNames:["Patient"],
            countryPhoneNoCode:"+1"
        }

        const createProcleBody = {
            email:values.Email,
            ...updateProcleBody
        }

        !isRegistered ? createProcleUser(createProcleBody) : updateProcleUser(updateProcleBody)
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const dateFormat = 'MM/DD/YYYY';

    const { Option } = Select;

    const prefixSelector = (
        <Form.Item name="prefix" initialValue={'+1'} noStyle>
          <Select style={{ width: 70}}>
            <Option value="+1">+1</Option>
          </Select>
        </Form.Item>
    )

    useEffect(() => {
        if (registrationData) {
            message.success('Profile Updated Successfully')
            dispatchSinglePatientData()
        }

        if(registrationError){
            console.log(registrationError)
        }

    }, [registrationData, registrationError]);

    // if single patient data is updated then route to dashboard
    useEffect(() => {
        if(singlePatientData){
            navigate(paths.DASHBOARD)
        }
    }, [singlePatientData])
  
    useEffect(() => {
        if(isAdmin || isProfessional){
            navigate("/admin/login")
        }
    },[])

    return (
        <Wrapper header="Please enter your information">
            <Row style={{ padding:"0 30px"}} gutter={{ xs: 8, sm: 16, md: 24, lg: 110 }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form
                        name="basic"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout='vertical'
                        className={styles.form_container}
                    >
                            <Form.Item
                                label={<label className={styles.input_label}>First Name</label>}
                                name="First_Name"
                                className="label-sty"
                                required={false}
                                initialValue = {patientInfo?.First_Name !== "null" ? patientInfo?.First_Name : ""}
                                rules={[
                                    { 
                                        required: true, 
                                        message: 'Please input your first name' 
                                    },
                                    {
                                        pattern: /^[A-Za-z]*$/,
                                        message: 'Numbers, spaces and special characters are not allowed'
                                    }
                                ]}
                            >
                                <Input  className="input" placeholder="Type here..."/>
                            </Form.Item>
                            <Form.Item
                                label={<label className={styles.input_label}>Last Name</label>}
                                name="Last_Name"
                                required={false}
                                initialValue = {patientInfo?.Last_Name && patientInfo?.Last_Name}
                                rules={[
                                    { 
                                        required: true, 
                                        message: 'Please input your last name' 
                                    },
                                    {
                                        pattern: /^[A-Za-z]*$/,
                                        message: 'Numbers, spaces and special characters are not allowed'
                                    }
                                ]}
                            >
                                <Input className="input" placeholder="Type here..."/>
                            </Form.Item>
                            <Form.Item
                                label={<label className={styles.input_label}>Email</label>}
                                name="Email"
                                required={false}
                                initialValue = {patientInfo?.Email && patientInfo?.Email}
                                rules={[{ type: 'email', required: true, message: 'Please input your email' }]}
                            >
                                <Input className="input" placeholder="Type here..." disabled/>
                            </Form.Item>
                            <Form.Item
                                label={<label className={styles.input_label}>Phone Number</label>}
                                name="Phone"
                                required={false}
                                initialValue = {patientInfo?.Phone && patientInfo?.Phone}
                                rules={[
                                    { 
                                        required: true, 
                                        message: 'Please input your phone number!',
                                    },
                                    { 
                                        min: 10, 
                                        message: 'Phone number must be 10 digits' 
                                    },
                                    { 
                                        pattern: new RegExp(/^[0-9\b]+$/), 
                                        message: 'Please input a valid phone number!'
                                    }
                                ]}
                            >
                                <Input 
                                    addonBefore={prefixSelector} 
                                    className='phone-input' 
                                    maxLength={10}
                                />
                            </Form.Item>
                            <Form.Item 
                                label={<label className={styles.input_label}>Date of Birth</label>}
                                name="Date_of_Birth"
                                required={false}
                                initialValue={patientInfo?.Date_of_Birth &&  moment(`${patientInfo?.Date_of_Birth.replaceAll('-', '/')}`, 'YYYY/MM/DD')}
                                rules={[
                                    { required: true, message: 'Please input your date of birth' },
                                ]}
                            >
                                <DatePicker  
                                    className="input" 
                                    placeholder="MM/DD/YYYY" 
                                    format={dateFormat}
                                    disabledDate={(current) => {
                                        return current && current > moment().endOf('day');
                                    }}
                                    allowClear={false}
                                />
                            </Form.Item>
                            <Form.Item
                                label={<label className={styles.input_label}>Sex</label>}
                                name="Sex"
                                required={false}
                                initialValue = {patientInfo?.Sex && patientInfo?.Sex}
                                rules={[
                                    { required: true, message: 'Please input your sex' }
                                ]}
                            >
                                <Select
                                    placeholder="Select your sex"
                                    className='select-primary'
                                    popupClassName='select-primary-popup'
                                    allowClear
                                >
                                    <Option value="male">Male</Option>
                                    <Option value="female">Female</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Button type="primary" style={{marginTop:'12px'}} htmlType="submit" className="button --primary" loading={registering}>
                                    Submit
                                </Button>
                            </Form.Item>
                    </Form>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className={styles.img_section}>
                        <img src={old_people} alt="old man and woman hugging" />
                    </div>
                </Col>
            </Row>
        </Wrapper>
    )
}

export default PatientInfo
