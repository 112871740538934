import React, {useEffect} from 'react';
import Layout from '../components/Layout'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Entry from '../pages/entry';
import Info from '../pages/info';
import Dashboard from '../pages/dashboard';
import PrivateRoute from './PrivateRoute';
import Exam from '../components/Exam';
import Results from '../pages/results';
import Suggestions from '../pages/suggestions';
import Summary from '../pages/summary';
import Login from '../pages/admin/login';
import Professional from '../pages/admin/professional';
import Organization from '../pages/admin/organization';
import AdminPrivateRoute from './AdminPrivateRoute';
import OrderSheet from '../pages/admin/order-sheet';
import ChangePassword from '../pages/admin/change-password';
import Profile from '../pages/admin/profile';
import useAuth from '../hooks/useAuth';
import { paths } from './paths';
import ManageProfessionals from '../pages/admin/manage-professional';
import ManagePatients from '../pages/admin/manage-patients';
import About from '../pages/about';
import Contact from '../pages/contact';

const AppRoutes = () => {
    const { isAuth } = useAuth()

   
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />}>

                    <Route path={paths.ABOUT_US} element={<About />}/>
                    <Route path={paths.CONTACT_US} element={<Contact />}/>

                    {/* Patient Public Routes */}
                    <Route path={paths.PATIENT_ENTRY} element={<Entry />} />
                    <Route path={paths.PATIENT_LOGIN} element={<Entry />} />
                    <Route index element={<Entry />} />

                    {/* Patient Private Routes */}
                    <Route path="" element={<PrivateRoute/>}>
                        <Route path={paths.PATIENT_INFO} element={<Info />}/>
                        <Route path={paths.DASHBOARD} element={<Dashboard/>}/>
                        <Route path={paths.EXAM} element={<Exam />}/>
                        <Route path={paths.RESULTS} element={<Results />}/>
                        <Route path={paths.SUGGESTIONS} element={<Suggestions />}/>
                        <Route path={paths.SUMMARY} element={<Summary />}/>
                    </Route>

                    {/* Admin Public Routes */}
                    <Route path={paths.ADMIN_LOGIN} element={<Login />} />
            

                    {/* Admin Private Routes */}
                    <Route path="" element={<AdminPrivateRoute/>}>
                        <Route path={paths.PROFESSIONAL} element={<Professional />} />
                        <Route path={paths.ORGANIZATION} element={<Organization />} />
                        <Route path={paths.MANAGE_PROFESSIONALS} element={<ManageProfessionals />} />
                        <Route path={paths.MANAGE_PATIENTS} element={<ManagePatients />} />
                        <Route path={paths.CHANGE_PASSWORD} element={<ChangePassword />} />
                        <Route path={paths.ORDER_SHEET} element={<OrderSheet />} />
                        <Route path={paths.PROFILE} element={<Profile />}/>
                    </Route>


                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes

const NoMatch = () => {
    return (
        <p>There's nothing here: 404!</p>
    );
};
