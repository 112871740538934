import { Button, Checkbox, Form, Input, message } from 'antd'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth'
import useAxios from '../../../hooks/useAxios'
import { GET_ORGS, LOGIN } from '../../../utils/endpoints'
import EntryContainer from '../../EntryContainer'
import styles from './index.module.css'
import { useSelector } from 'react-redux'

const LoginContainer = () => {

    const auth = useSelector(state => state.auth);

    const { isAdminAuth, isAdmin, isAdminUpdated, isProfessional, isPasswordUpdated, dispatchContactData, saveAuth } = useAuth()

    const navigate = useNavigate()
    const [formValues, setFormValues] = useState({})

    const {
        data: orgsData,
        error: orgsError,
        isLoading: fetchingOrgs,
        dispatchFetch: dispatchFetchOrgs,
      } = useAxios({ method: "POST", url: GET_ORGS});

    const {
        data: loginData,
        error: loginError,
        isLoading: loggingIn,
        dispatchFetch: dispatchLogin,
      } = useAxios({ method: "POST", url: LOGIN});


    const onFinish = (values) => {
        setFormValues(values)
        const body = new URLSearchParams()
        body.append('email', values.email.trim())
        body.append('password', process.env.REACT_APP_ADMIN_PASSWORD)
        body.append('asList', 1)
        body.append('parentOrg', process.env.REACT_APP_ORG_ID)
        dispatchFetchOrgs(body)
    };
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    useEffect(() => {
        const body = new URLSearchParams()
        if(orgsData){
            body.append('org_id', orgsData?.data[0]?.organizations[0]?.org_id)
            body.append('username', formValues?.email.trim())
            body.append('password', formValues?.password.trim())
            dispatchLogin(body)
        }
    }, [orgsData])


    useEffect(() => {
        if (loginData) {
            if(loginData?.data?.error){
                message.error("Invalid Email or Password")
            }
            saveAuth({
                patientID: loginData?.data?.user_id,
                token: loginData?.data?.access_token,
            }); 
        }
    }, [loginData]);

    useEffect(() => {
        if(auth){
            dispatchContactData()
        }
    },[auth])

    useEffect(() => {
        (isAdminAuth) && message.success("Logged In Successfully")
    }, [isAdminAuth])

    useEffect(() => {
        loginError && message.error(loginError)
    }, [loginError])

    useEffect(() => {
        if(orgsError){
            message.error("Invalid Email or Password")
        }
    }, [orgsError])
   
    // when password hasn't been changed
    if(isAdminAuth && (isProfessional || isAdmin) && !isPasswordUpdated){
        return(
            <Navigate to="/admin/change-password"/>
        )
    }

    if(isAdminAuth && (isProfessional || isAdmin) && isPasswordUpdated && !isAdminUpdated){
        return(
            <Navigate to="/admin/profile"/>
        )
    }   

    // when admin is authenticated
    if(isAdminAuth && isAdmin && isPasswordUpdated){
        return(
            <Navigate to="/admin/organization"/>
        )
    }

    // when health professional is authenticated
    if(isAdminAuth && isProfessional && isPasswordUpdated){
        return(
            <Navigate to="/admin/professional"/>
        )
    }


    return (
        <>
            <EntryContainer title="Admin Entry" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Form.Item
                    name="email"
                    label="Enter Email Address"
                    required={false}
                    rules={[
                        { required: true, message: 'Please input your email' },
                        { type: 'email', message: 'Please enter a valid email' }
                    ]}
                >
                    <Input className="input" placeholder="Email Address"/>
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Enter Password"
                    required={false}
                    rules={[{ required: true, message: 'Please input your password' }]}
                >
                    <Input.Password className="input" placeholder="Password"/>
                </Form.Item>
                {/* <Form.Item name="remember" valuePropName="checked" wrapperCol={{ span: 24 }}>
                    <Checkbox>Remember me</Checkbox>
                </Form.Item> */}
                <Form.Item wrapperCol={{ span: 24 }}>
                    <Button 
                        style={{marginTop:'12px'}} 
                        htmlType="submit" 
                        className="button --secondary" 
                        loading={fetchingOrgs || loggingIn}
                    >
                        LOG IN
                    </Button>
                </Form.Item>
                <Form.Item>
                    <p className={styles.forget_password}  onClick={() => navigate('/')}>LOGIN AS PATIENT</p>
                </Form.Item>
            </EntryContainer>
        </>
    )
}

export default LoginContainer