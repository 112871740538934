import React from 'react'
import PatientEntry from '../components/PatientEntry'

const Entry = () => {
    return (
        <>
            <PatientEntry />
        </>
    )
}

export default Entry
