import React from 'react'
import ChangePasswordForm from '../../components/Admin/ChangePasswordForm'

const ChangePassword = () => {
    return (
        <>
            <ChangePasswordForm/>
        </>
    )
}

export default ChangePassword
