import React from 'react'
import styles from './index.module.css'
import { useLocation } from 'react-router-dom'
import { getFullName } from '../../utils/helper';


const ResultsHeader = ({adminHeaderText}) => {
   // get url search params
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const isAdmin = searchParams.get('user') === "admin"
  
    return (
        <header className={styles._}>
            { 
                isAdmin ?
                <h3>{adminHeaderText}</h3> :
                <>
                    <h3>Congratulations!</h3>
                    <p>You've completed the items necessary for your Annual Wellness Visit. <br />Please schedule an appointment with your healthcare provider to discuss the findings. </p>
                </>
            }
        </header>
    )
}

export default ResultsHeader
