import { Modal } from 'antd'
import React from 'react'
import './modal.css'

const ModalComponent = ({children, title, isModalVisible, handleOk, handleCancel, width}) => {
  return (
    <Modal title={title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={width} footer={null}>
      {children}
    </Modal>
  )
}

export default ModalComponent