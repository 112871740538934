import { Button, Col, message, Result, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useAxios from '../../hooks/useAxios'
import { EXAM_LIST } from '../../utils/examdata'
import ExamEnd from '../ExamEnd'
import ExamLayout from '../ExamLayout'
import ExamStart from '../ExamStart'
import QuestionExtras from '../QuestionExtras'
import QuestionOptions from '../QuestionOptions'
import QuestionWrapper from '../QuestionWrapper'
import LoadingState from '../LoadingState';
import './transition.css'
import { paths } from '../../routes/paths';
import { LOAD_EXAM } from '../../redux/actions/types';
import { CONTACT } from '../../utils/endpoints';


const Exam = () => {

    let navigate = useNavigate()
    let dispatch = useDispatch()
    let params = useParams()

    const { patientID, patientInfo } = useSelector(state => state.auth);
    const {exam_info} = useSelector(state => state.exam)

    const [showExtras, setShowExtras] = useState(false)
    const [startExam, setStartExam] = useState(false)
    const [endExam, setEndExam] = useState(false)
    const [extraType, setExtraType] = useState()
    const [index, setIndex] = useState(0)
    const [examProgress, setExamProgress] = useState()
    const [points, setPoints] = useState([])
    const [diets, setDiets] = useState([])
    const [snaqScore, setSnaqScore] = useState()
    const [showNext, setShowNext] = useState(false);
     
    const [answerResponse, setAnswerResponse] = useState({
        Patient_ID : patientID,
        Patient_Info: patientID,
        Organization_Info : patientInfo?.Organization,
        Exam: exam_info?.id
    })

    // Get RCS test
    const getRcsTest = EXAM_LIST.find( ({title}) => { 
        return title === 'rcs'
    })

    // Get current exam being answered by patient
    const currentExam = EXAM_LIST.find( ({title}) => { 
        return title === params.exam
    })

    // Get current exam index
    const currentExamIndex = EXAM_LIST.findIndex( ({title}) => { 
        return title === params.exam
    })

    // Get next exam in exam array list
    const nextExam = EXAM_LIST[currentExamIndex + 1]?.title

    // Get last exam in exam array list
    const lastExam = EXAM_LIST[EXAM_LIST.length - 1]

    const { data: questionData, error: questionError, isLoading: loadingQuestions, dispatchFetch: dispatchQuestions } = useAxios({ method: "GET", url: `/full_metadata.php/${params.exam}`});

    const { data: answerData, error: answerError, isLoading: loadingAnswers, dispatchFetch: submitAnswers } = useAxios({ method: "POST", url: `/index.php/${params.exam}`});

    const { data: postExamData, error: postExamError, isLoading: submitting, dispatchFetch: submitExam } = useAxios({ method: "POST", url: `/index.php/exam`});

    const { data: testData, error: testError, isLoading: loadingTest, dispatchFetch: fetchTest } = useAxios({ method: "GET", url: `/index.php/${params.exam}?Exam=${exam_info?.id}&asList=1`});

    // Fetch patient exam objects
    const { data: examData, error, isLoading, dispatchFetch: fetchExamData } = useAxios({ method: "GET", url: `/index.php/exam?Patient_Info=${patientID}`});

    const { data: frailData, dispatchFetch: fetchFrail } = useAxios({ method: "GET", url: `/index.php/frail?Exam=${exam_info?.id}&asList=1`});

    // Fetch all health professional's data
    const {data: allContactData, isLoading:loadingAllContacts, dispatchFetch: fetchAllContactData,} = useAxios({ method: "GET", url: CONTACT + '/all', params: { asList: 1} });


    const frailTest = frailData?.data.data[frailData?.data?.data.length - 1]

    // Get patient exam
    const examEntries = examData && Object.entries(examData?.data?.data);
    const currentPatientExam = examEntries?.[examEntries?.length - 1]?.[1]

    console.log(questionData?.data)

    let questionDataArray = questionData?.data["fields metadata"]
    // Sort Question by display order
    questionDataArray?.sort(function (a, b) {
        return a.view_display_order - b.view_display_order;
    });
    
    // Filter non questions out (objects with view_display_order === "0")
    let ordered_questions = questionDataArray?.filter(function(order) {
        return order.view_display_order !== 0;
    });


    //Get last question
    const lastQuestion = ordered_questions?.length - 1

    // Get patient assigned professional
    const assignedProfessional = allContactData?.data?.data?.find((contact) => contact?.id === exam_info?.Assigned_Professional)

    // Function to update progress
    const getExamProgress = () => {
        if(currentExamIndex > EXAM_LIST.length - 1){
            setExamProgress(null)
        }
        else if(params.exam === 'snaq' && (frailTest?.Loss_of_weight === "0" || snaqScore > 14)){
            setExamProgress('rcs')   
        }
        else if(params.exam === "weight-survey" && (answerResponse?.Trouble_Swallowing === "0")){
            setExamProgress('rcs')  
        }
        else if(params.exam === "rcs" && totalScores > 7){
            setExamProgress('sarcf')
        }
        else if(params.exam === "sarcf" && (answerResponse.Times_Fallen === "None" && patientInfo?.Sex === "Male")){
            setExamProgress('adam')
        }
        else if(params.exam === "sarcf" && (answerResponse?.Times_Fallen === "None" && patientInfo?.Sex === "Female")){
            setExamProgress('what-matters')
        }
        else if(params.exam === "f3alls" && patientInfo?.Sex === "Female"){
            setExamProgress('what-matters')
        }
        else{
            setExamProgress(nextExam)
        }
    }

    // Function to go to next question
    const nextQuestion = () => {
        setIndex((oldIndex) => {
            const index = oldIndex + 1
            if (index > ordered_questions.length - 1) {
                setEndExam(true)
                return 0
            } else {
                return index
            }
        })
    }

    //Function to skip question based on option
    const skipQuestion = (option) => {
        if (params.exam === 'frail' && (index === 0  && option === "0")){
            setIndex((oldIndex) => {
                const index = oldIndex + 3
                return index
            })
        }
    }

    // Function to add selected option
    const checkSelectedOption = (option, point) => {
        
        const questionName = ordered_questions[index].name.replace("__c","")
        
        setAnswerResponse((prevAnswerResponse) => ({
            ...prevAnswerResponse,
            [questionName]:option
        }))

        skipQuestion(option)
        nextQuestion()
    
        setPoints((prevPoint) => ([
            ...prevPoint,
            point
        ]))
    }

    // Function to check rcs selected option
    const checkRcsSelectedOption = (value, point) => {

        const questionName = ordered_questions[index].name
        setAnswerResponse((prevAnswerResponse) => ({
            ...prevAnswerResponse,
            [questionName]:value
        }))
        nextQuestion()
        setPoints((prevPoint) => ([
            ...prevPoint,
            point
        ]))
    }

    //Function to handle Multipicklist
    const handleMultiPickListOption = (value, idx) => {
        setDiets((prevDiet) => ([
            ...prevDiet,
            value
        ]))
    

        // Remove selected item from diets array
        if(diets.includes(value)){
            const removeDiet = diets.filter((item) => item !== value)
            setDiets(removeDiet)
        }

        // Remove No option from array
        if(diets.includes("No")){
            const removeNoDiet = diets.filter((item) => item !== "No")
            setDiets([...removeNoDiet, value])
        }


        // Remove all selected item when "No" is selected
        if(value === "No"){
            setDiets(["No"])
            if(diets.includes("No")){
                const removeDiet = diets.filter((item) => item !== "No")
                setDiets(removeDiet)
            }
        } 
    }

    // Calculating total scores
    const totalScores = points.reduce((acc, current, i) => {
        
        // snaq scoring is indexed 1
        if(params.exam === 'snaq'){
            return acc + (current + 1)
        }

        // statement to ignore scoring for fatique sub questions
        if(params.exam === 'frail' && points.length === 8){
            if(i===1||i===2|| i===3){
                current = 0
                return acc + current
            }
        }

        // statement to only apply scoring to the last six questions
        if(params.exam === 'adls'){
            if(i < 6){
                current = 0
                return acc + current
            }
        }
        return acc + current
    }, 0)

    // Function to get an exam score for optional routing
    const getExamScore = () => {
        if(params.exam === 'snaq'){
            setSnaqScore(totalScores)
        }
    }
   
    // Function to add extras to a particular question *Extras can be weight calculator, picture or any other
    const displayQuestionExtras = () => {
        if ((params.exam === 'frail' && index === lastQuestion) || (params.exam === 'frail-nh' && index === 4)){
            setShowExtras(true)
            setExtraType('weight')
        } 
        else if(params.exam === 'adls' && (index === 0 || index === 1)){
            setShowExtras(true)
            setExtraType('image')
        }
        else if(params.exam === 'rcs' && (index === 0 || index === 4)){
            setShowNext(true)
            setShowExtras(false)
        }
        else{
            setShowExtras(false)
            setExtraType('')
            setShowNext(false)
        }
    }

    // Function to remove start component when starting optional exams
    const startOptionalExams = () => {
        if(params.exam === 'weight-survey' || params.exam === 'eat-survey' || params.exam === 'f3alls' || params.exam === 'hearing-survey' || params.exam === 'iadls'){
            setStartExam(true)
        }
    }

    // Function to update rcs questions
    const updateRcsQuestions = () => {
        if(params.exam === 'rcs'){
            ordered_questions = getRcsTest?.questions
            return ordered_questions
        }
    }
    updateRcsQuestions()

    const goHome = () => {
        navigate('/')
    }

    useEffect(() => {
        if(endExam){
            getExamProgress()
        }
    }, [endExam])

    useEffect(() => {
        if(endExam && examProgress){
            let data = {
                ...answerResponse,
                Score:totalScores
            }

            // f3alls and what-matters does not accept Score
            if(params.exam === "f3alls" || params.exam === "what-matters"){
                data = {
                    ...answerResponse
                }
            }
            const body = [
                {
                    "id": "",
                    data
                }
            ]
            submitAnswers(body)
            submitExam([
                {
                    "id": exam_info?.id,
                    data: {
                        Progress: examProgress
                    }
                }
            ])
        }
    },[examProgress])
    
    useEffect(() => {
        startOptionalExams()
        getExamScore()
    }, [])

    useEffect(() => {
        displayQuestionExtras()
    }, [showExtras, showNext, index])

    useEffect(() => { 
        fetchTest()
    }, [])

    useEffect(() => {
        dispatchQuestions()
    }, [params.exam])

    useEffect(() => {
        fetchFrail()
    }, [answerData])

    useEffect(() => {
        fetchAllContactData()
    }, [])

    // Reroute to current exam if exam is not completed
    // useEffect(() => { 
    //     if(currentPatientExam){
    //         navigate(`${paths.DASHBOARD}/${currentPatientExam?.Progress}`)  
    //         setStartExam(false)
    //         setEndExam(false)
    //         startOptionalExams()
    //         // window.location.reload()
    //     }
    // }, [currentPatientExam , startExam, endExam])

    useEffect(() => {
        fetchExamData()
    }, [])

    useEffect(() => {
        if(postExamData){
            fetchExamData()
            // fetchTest()
        }
    }, [postExamData])

    if(loadingQuestions || loadingTest){
        return(
            <LoadingState text="Loading Test" />
        )
    }

    if(testData && testData?.data?.data.length !== 0){
        return(
            <div className="result_container">
                <Result
                    status="info"
                    title={`You have completed ${currentExam?.title.toUpperCase()} test`}
                    subTitle={params?.exam === 'what-matters' ? "" : "Kindly click the button below to go to the next test"}
                    extra={
                        params?.exam === 'what-matters' ? null :
                        <Button 
                            className="button --primary" key="restart"
                            onClick={() => {
                                navigate(`${paths.DASHBOARD}/${currentPatientExam?.Progress}`)
                                window.location.reload()
                            }}
                        >
                            Start {currentPatientExam?.Progress.toUpperCase()} Test
                        </Button>
                    }
                />
            </div>
        )
    }

    if(params.exam === 'adam' && patientInfo?.Sex !== 'Male'){
        return(
            <ExamLayout title={currentExam?.title}> 
                <div style={{textAlign:'center'}}>
                   <h2>This test is for males only. You are not eligible to take this test.</h2>
                   <Button type="primary" style={{width:'200px', marginTop:"10px"}} className="button --card-button-active" onClick={() => goHome()}>Go Home</Button>
                </div>
            </ExamLayout>
        )
    }

    // if((currentPatientExam?.Progress !== params?.exam) && (testData?.data?.data.length !== 0)){
    //     return(
    //         <Result
    //             status="info"
    //             title={`You haven't completed your ${currentPatientExam?.Progress.toUpperCase()} test`}
    //             subTitle="Kindly click the button below to go to the next test"
    //             extra={
    //             <Button 
    //                 className="button --primary" key="restart"
    //                 onClick={() => {
    //                     navigate(`${paths.DASHBOARD}/${currentPatientExam?.Progress}`)
    //                     window.location.reload()
    //                 }}
    //             >
    //                 Start {currentPatientExam?.Progress.toUpperCase()} Test
    //             </Button>
    //             }
    //         />
    //     )
    // }

    return (
        <CSSTransition in={true} appear={true} timeout={1000} classNames='fade'>
            <ExamLayout title={currentExam?.title} index={index}>
                {
                    !startExam ? (
                        <ExamStart title={currentExam?.title} description={currentExam?.description} setStartExam={setStartExam}/>
                    ) : 
                    (
                        !endExam ? ( 
                        ordered_questions && ordered_questions?.length !== 0  ?
                            <TransitionGroup>
                                <CSSTransition 
                                key={ordered_questions[index].name} 
                                timeout={1000} 
                                appear={true}
                                classNames='fade'>
                                    <QuestionWrapper 
                                            examType={params.exam} question={ordered_questions.length !== 0 && (ordered_questions[index]?.label)} 
                                            sub_label={ordered_questions[index]?.sub_label}
                                        >
                                            <Row gutter={16}>
                                                <Col xs={24} sm={24} md={showExtras ? 12 : 24} lg={showExtras ? 12 : 24} xl={showExtras ? 12 : 24}>
                                                    <QuestionOptions 
                                                        type={ordered_questions[index]?.type} 
                                                        type_value={ordered_questions[index]?.type_value}
                                                        questionName={ordered_questions[index].name.replace("__c","")}
                                                        checkSelectedOption={checkSelectedOption}
                                                        checkRcsSelectedOption={checkRcsSelectedOption}
                                                        handleMultiPickListOption={handleMultiPickListOption}
                                                        examType={params.exam}
                                                        index={index}
                                                        diets={diets}
                                                        nextQuestion={nextQuestion}
                                                        setAnswerResponse={setAnswerResponse}
                                                        setPoints={setPoints}/>   
                                                </Col>
                                                {
                                                    (showExtras || showNext) &&
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <QuestionExtras 
                                                            show={showExtras} 
                                                            type={extraType} 
                                                            examType={params.exam} 
                                                            index={index}  
                                                            showNext={ordered_questions[index]?.next || showNext}
                                                            handleNextClick={nextQuestion} />
                                                    </Col>
                                                }
                                        </Row>
                                    </QuestionWrapper>
                                </CSSTransition>
                            </TransitionGroup> :
                        <h2 style={{textAlign:'center'}}>
                            No Questions added for {currentExam?.title.toUpperCase()} yet
                        </h2>
                        ) : <ExamEnd 
                                isLoading={loadingAnswers} 
                                endExam={endExam}
                                setEndExam={setEndExam} 
                                setStartExam={setStartExam}
                                examType={params.exam} 
                                snaqScore={snaqScore}
                                examProgress={examProgress}
                                setExamProgress={setExamProgress}
                                answerData={answerData}
                                answerError={answerError}
                                answerResponse={answerResponse}
                                submitAnswers={submitAnswers}
                                refreshQuestions={() => dispatchQuestions()} 
                                assignedProfessional={assignedProfessional}
                            />
                    ) 
                }
            </ExamLayout>
        </CSSTransition>
    )
}

export default Exam
